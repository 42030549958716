import React from 'react';
// import { connect } from 'react-redux';



export default function Filter(props) {

  let { getAnsweredEmployeesData, handleSelectEmployee } = props;

  let [showFilter, handleOpenFilter] = React.useState(false);



  let { crrSelectedEmp } = props;



  let [searchName, handleSearch] = React.useState("");

  function tryCatch(valueX, searchNameX) {
    let value1 = true;
    try {
      value1 = valueX && valueX.match(new RegExp(`${searchNameX}`, "gi"));
    } catch (e) {
      return true;
    }
    return value1;
  }

  let showNotFound = true;
  if (getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0) {
    let countY = 0;
    getAnsweredEmployeesData.forEach((emp, index) => {
      if (tryCatch(emp.name, searchName)) {
        countY++
      }
    })
    if (countY) {
      showNotFound = false
    }
  }

  return (
    <>



      <div className="cursor-pointer bg-white  border pl-2 pr-1 relative py-1.5 rounded-md select-none md:w-56 w-full">
        {/* <div>
          <h1 className='text-xs font-medium text-gray-800' onClick={() => handleOpenFilter(!showFilter)}>Employee: </h1>
        </div>
        <p onClick={() => handleOpenFilter(!showFilter)} className="text-xs px-2 text-gray-900">{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Select"}</p>
        <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span> */}

        <p className='text-xs text-[#212121]/70 font-medium' onClick={() => handleOpenFilter(!showFilter)}>Employee</p>
        <div className='flex justify-between items-center  space-x-1.5' onClick={() => handleOpenFilter(!showFilter)}>
          <p onClick={() => handleOpenFilter(!showFilter)} className="text-sm font-medium  text-[#212121]">{crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "Select"}</p>
          <span onClick={() => handleOpenFilter(!showFilter)} className="material-icons ">arrow_drop_down</span>
        </div>





        {showFilter && getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0 ?
          <div className="z-10 absolute right-0 top-10 bg-white shadow-lg px-2 py-3 font-normal text-xs w-full h-60 overflow-x-auto customscroll2 rounded-md">

            <div className="border relative  mb-4 rounded-md bg-white overflow-hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-search absolute  top-1.5 left-1.5"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#3D405B"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
              <input
                type="text"
                name="searchName"
                value={searchName}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                className="text-sm w-full pl-8 p-2 rounded-sm focus:outline-none text-[#707070] placeholder:text-[#707070]  "
              />
            </div>
            {getAnsweredEmployeesData && getAnsweredEmployeesData.length > 0 ?
              getAnsweredEmployeesData.map((emp, index) =>
                tryCatch(emp.name, searchName) ? (

                  <div key={index} className="py-2 m-1 flex items-center cursor-pointer"
                    onClick={() => { handleSelectEmployee(emp); handleOpenFilter(false) }}
                  >
                    <input type="checkbox" checked={crrSelectedEmp.emp_id === emp.emp_id} onChange={() => { }} />
                    <label className="ml-2 font-medium">{emp.name}</label>
                  </div>
                ) : (index === 0 && showNotFound && <div className="py-2 m-1 flex items-center cursor-pointer">
                  <label className="ml-2 font-medium text-gray-400">No Result Found!</label>
                </div>)

              ) : null}
          </div>
          : null}
      </div>








      {/* </div> */}

    </>
  );
}
