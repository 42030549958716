import React from "react";
import moment from "moment";

export default function Item(props) {
  let { CurrentManagerData, CurrentSurveyId, surveyLogo, teamSize, createdAt, getMngrTeamSizeData } = props;
  console.log("CurrentSurveyId---->", CurrentSurveyId)

  let teamAllCount = getMngrTeamSizeData && getMngrTeamSizeData[0] && getMngrTeamSizeData[0].count ? getMngrTeamSizeData[0].count : 0;

  return (
    <>
      <section className=" space-y-2   px-10">
        <main className="   ">
          <section className=" flex flex-col items-center justify-between ">
            <div className=" text-center space-y-6  ">
              {(CurrentSurveyId && CurrentSurveyId.toString() === "66263d1e9300643cda1e1d06") ?
                <div className="flex justify-between items-center pt-6 px-10 w-full"  >
                  <img
                    src={surveyLogo}
                    className="w-46 object-cover"
                    alt="default_survey_logo"
                  />
                  <img
                    src={"/img/managerreport/tata-logo2.png"}
                    className="h-12 object-cover"
                    alt="default_survey_logo"
                  />
                </div>
                :
                <div className="flex justify-center pt-6 px-10 w-full" >
                  <img
                    src={surveyLogo}
                    className="w-32 object-cover"
                    alt="default_survey_logo"
                  />
                </div>}
              <div className="space-y-8 ">
                <div className="text-center w-full pt-6">
                  <div className="text-center text-4xl leading-none text-[#2364AD] font-bold">
                    Manager Score Card 2024
                  </div>
                </div>
                <div className="bg-[#FFFFFF] text-[#212121] text-center  mx-auto p-6 py-10 space-y-3">
                  <h1 className="text-4xl font-bold">{CurrentManagerData && CurrentManagerData.name ? "Name - " + CurrentManagerData.name : ""}</h1>
                  <p className="text-3xl font-bold">{CurrentManagerData && CurrentManagerData.id ? "ID - " + CurrentManagerData.id : ""}</p>
                </div>
              </div>

              <div className="space-y-10 ">
                <div class="text-center space-y-2  mx-auto">
                  {/* <p class="text-lg text-[#212121] text-center italic">
                    This report is confidential and should not be distributed <br /> without permission.
                  </p> */}
                  <div className="text-center text-lg leading-none text-[#212121] italic pt-4 ">
                    {createdAt}
                  </div>
                </div>
                <div class="text-center mx-auto grid grid-cols-2 bg-[#2364AD]/10">
                  <div className="border-r-2 border-white p-4">
                    <p class="text-base text-[#212121] font-bold text-center">
                      Your Team Size
                    </p>
                    <div className="text-center text-3xl leading-none text-[#2364AD] pt-4 font-bold">
                      {teamAllCount}
                    </div>
                  </div>
                  <div className="p-4">
                    <p class="text-base text-[#212121] font-bold text-center">No. of Respondents</p>
                    <div className="text-center text-3xl leading-none text-[#2364AD] pt-4 font-bold">
                      {teamSize}
                    </div>
                  </div>
                </div>

                <div className="bg-[#FFFFFF] text-[#212121] text-center p-6 py-6 space-y-2">
                  <p className="text-base"> <b>Disclaimer :</b> These are anonymous responses, where we invited employees to share their experiences with their manager. The survey scores should be taken constructively for self-development.</p>
                </div>
              </div>
              <div class="flex justify-center -mt-6 -mx-10">
                {/* <img
                  src="/img/managerreport/manager-report-cover-two.webp"
                  class="w-full h-full object-contain filter "
                /> */}
              </div>
            </div>

          </section>
        </main>
      </section>
    </>
  );
}
