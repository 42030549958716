import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, surveyActions, questionsActions, dashboardActions } from '../../../_actions';
import { accessModule } from '../../../_helpers';
import Select2 from 'react-select';
import CryptoJS from 'crypto-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from '../Header/Header';
import Sidebar from '../../Overview/Sidebar/Sidebar';

import moment from 'moment';


import WelcomePage from './Components/WelcomePage/WelcomePage';
import LogicPage from './Components/LogicPage/LogicPage';
import DashboardDesign from './Components/DashboardDesign/DasboardDesign';
import DesignPage from './Components/DesignPage/DesignPage';

import SingleChoice from './Components/QuestionType/SingleChoice';
import DMGSingleChoice from './Components/QuestionType/DMGSingleChoice';
import TextBox from './Components/QuestionType/TextBox';
import DMGTextBox from './Components/QuestionType/DMGTextBox';

import MultilineText from './Components/QuestionType/MultilineText';
import RatingScale from './Components/QuestionType/RatingScale';
import NPS from './Components/QuestionType/NPS';
import DMGNPS from './Components/QuestionType/DMGNPS';
import NPSV2 from './Components/QuestionType/NPSV2';
import Email from './Components/QuestionType/Email';
import Number from './Components/QuestionType/Number';
import Date from './Components/QuestionType/Date';
import YesNo from './Components/QuestionType/YesNo';
import Dropdown from './Components/QuestionType/Dropdown';
import Statement from './Components/QuestionType/Statement';
import Matrix from './Components/QuestionType/Matrix';
import GroupQuestions from './Components/QuestionType/GroupQuestions';
import LocationQuestions from './Components/QuestionType/Location';
import MultipleChoice from './Components/QuestionType/MultipleChoice';
import Preview from './Components/Preview/Surveylink/MainPage';
import LoadingOverlay from 'react-loading-overlay';
import DeleteSurveyConfirm from './Components/Modal/DeleteQuestionConfirm';


import QuestionRestrictionModal from './Components/Modal/QuestionRestrictionModal';
import CustomQueLibrary from './Components/CustomQueLibrary/CustomQueLibrary';
import NudgeModal from './Components/NudgeSetting/NudgeModal';

import ModifyIndexModal from './Components/Modal/ModifyIndexModal';
import SelectFilter from './Components/DMGConfig/SelectFilter';


import CreateParameterModal from './Components/CreateParameter/CreateParameterModal';
import { TbChevronDown, TbChevronUp, TbCopy, TbEye, TbFile, TbPencil, TbSend, TbTrash } from "react-icons/tb";



class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddQuestion: true,
      showWelcomePage: false,
      showPreviewPage: false,
      showTranslatePage: false,
      showDesignPage: false,
      showLogicPage: false,
      showDashboardDesignPage: false,

      showDeleteQueModal: false,
      logoUploadError: '',
      imageUploadError: '',
      CurrentQuestionData: '',
      CurrentQuestionName: '',
      currQuesTranslEditId: '',
      showPleaseSaveQuesModal: "",

      showIndexFilter: false,
      selectedIndexFilterValue: "",
      ShowTranslWelcome: false,

      showTranslAttributes: false,

      showOptionHeading: false,

      translQuestion: {},
      translSubQuestionsMatrix: {},

      translSurvey: {},


      currQuesEditId: '',
      welcomeFormData: {
        "bgType": 1,
        "designTemplate": "v4"
      },

      showBucketDropdown: false,
      showDimensionDropdown: false,

      paraBucketName: '',
      paraDimensionName: '',

      questionTypeTemplate: '',

      showQuestionTypeList: false,

      instructionCheck: true,

      expiryformData: {
        "id": "",
        "isExpire": "",
        "expireTime": moment().format("YYYY-MM-DD")
      },
      showParameterModal: false,

      showEditSurveyName: false,
      surveyUpdateFormData: '',
      imageWebLinkLogo: '',
      imageWebLinkLogo2: '',
      dashQuesTypeRank: {},

      crrQuestionDesignObj: {},

      ColorCodeList: [
        {
          "name": 'Question and Survey Text',
          "value": 'textColor',
          "no": 1
        },

        {
          "name": 'Option Background',
          "value": 'OptionColor1',
          "no": 3
        },
        {
          "name": 'Option Text',
          "value": 'OptionColor2',
          "no": 4
        },
        {
          "name": 'Option Text Active',
          "value": 'OptionColor2x',
          "no": 9
        },
        {
          "name": 'Selected Option',
          "value": 'OptionColor3',
          "no": 5
        },
        {
          "name": 'Button Background',
          "value": 'buttonColor1',
          "no": 6
        },
        {
          "name": 'Button Text',
          "value": 'buttonColor2',
          "no": 7
        },
        {
          "name": 'Survey Background',
          "value": 'bgColor',
          "no": 2
        },
        {
          "name": 'Header Color',
          "value": 'headerColor',
          "no": 8
        },
      ],
      selectColorType: 'textColor',

      showSidebar: false,

      CrrRoute: "survey",

      crrSurvey: "",
      tempSaveQues: {},



      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',


      language: 'hi',
      CurrTranslShortName: '',


      showLanguageModal: false,
      showQuestionLibraryModal: false,
      QuestionLibraryFinalList: [],
      questionTypeTemplateGroup: '',
      logicQuesId: "",
      logicOptions: [],
      showCreateParameterModal: false,
      parameterFormData: {
        "name": "",
        "label": "",
        "parameterTypeId": "",
        "parameterTypeLevel": ""
      },
      isBucket: false,
      CurrBucketId: false,
      CrrParaLibCollection: [],
      showSettings: false,
      allqueSeconds: 0,
      deleteQuestionList: [],
      openAddNudgeModal: "",


      openAddDMGModal: "",
      showFilter: "",
      showValue: "",
      FilterValues: [],
      currFilterName: ""


    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 5000
    }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));

    this.props.dispatch(dashboardActions.getParameterTypeList(dataX));


    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data, "getQuestionListBySurveyID"));
    this.props.dispatch(surveyActions.getQuestionTypeList());
    // this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 5000
    }
    this.props.dispatch(surveyActions.getParameterList(data1));


    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));





  }
  static getDerivedStateFromProps(nextProps, prevState) {
    //////console.log("nextProps: ", nextProps)
    if (nextProps.user.logoUploadSuccess) {
      return {
        ...nextProps,
        imageWebLinkLogo: nextProps.user.filesDetailsLogo.imageUrl
      }
    }
    if (nextProps.user.logo2UploadSuccess) {
      return {
        ...nextProps,
        imageWebLinkLogo2: nextProps.user.filesDetailsLogo2.imageUrl
      }
    }
    if (nextProps.user.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.user.filesDetails.imageUrl
      }

    } else {
      return {
        ...nextProps
      }
    }

  }

  // onAddQuestion=(check)=>{
  //   this.setState({
  //       showAddQuestion:!check,
  //       showWelcomePage:false
  //   })
  // }

  handleShowQuestionType = (check) => {
    //console.log('check:', check)
    this.setState({ showQuestionTypeList: !check })
  }

  handleQuestionType = (quesType) => {
    this.setState({
      questionTypeTemplate: quesType,
      showQuestionTypeList: false
    })
  }

  handleQuestionTypeGroup = (quesType) => {
    this.setState({
      questionTypeTemplateGroup: quesType
    })
  }


  handleAddQuestion = (isGroupQuestion, GroupMainId, SubQuesTemplate) => {

    //console.log('working', 'W')
    let { questionTypeTemplate } = this.state;


    let QuesTypeTemplate = questionTypeTemplate.templateId;
    let QuesTypeID = questionTypeTemplate.id;
    let ParentId = null;


    if (isGroupQuestion) {
      QuesTypeTemplate = SubQuesTemplate.templateId;
      QuesTypeID = SubQuesTemplate.id;
      ParentId = GroupMainId;
    }


    if (QuesTypeTemplate && QuesTypeID) {





      let Ques = {};
      if (QuesTypeTemplate === 1) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 6, name: "Strongly Agree" },
            { weightage: 5, name: "Agree" },
            { weightage: 4, name: "Slightly Agree" },
            { weightage: 3, name: "Slightly Disagree" },
            { weightage: 2, name: "Disagree" },
            { weightage: 1, name: "Strongly Disagree" },
          ]
        }
      }
      else if (QuesTypeTemplate === 2 || QuesTypeTemplate === 9) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 1, name: "Option A" },
            { weightage: 2, name: "Option B" },
            { weightage: 3, name: "Option C" },
            { weightage: 4, name: "Option D" }
          ]
        }
      }
      else if (QuesTypeTemplate === 3 || QuesTypeTemplate === 4) { //3.Text Box	, 4.Multi-line Text	
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 1, name: "Option A" }
          ]
        }
      }
      else if (QuesTypeTemplate === 14 || QuesTypeTemplate === 15) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: []
        }
      }
      else if (QuesTypeTemplate === 5) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 1, name: "Star 1" },
            { weightage: 2, name: "Star 2" },
            { weightage: 3, name: "Star 3" },
            { weightage: 4, name: "Star 4" },
            { weightage: 5, name: "Star 5" }
          ]
        }
      }
      else if (QuesTypeTemplate === 6 || QuesTypeTemplate === 22) { // 6.NPS 1-10 
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 0, name: "0" },
            { weightage: 1, name: "1" },
            { weightage: 2, name: "2" },
            { weightage: 3, name: "3" },
            { weightage: 4, name: "4" },
            { weightage: 5, name: "5" },
            { weightage: 6, name: "6" },
            { weightage: 7, name: "7" },
            { weightage: 8, name: "8" },
            { weightage: 9, name: "9" },
            { weightage: 10, name: "10" }
          ]
        }
      }
      else if (QuesTypeTemplate === 16) { // 16. NPS 0-10
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 0, name: "0" },
            { weightage: 1, name: "1" },
            { weightage: 2, name: "2" },
            { weightage: 3, name: "3" },
            { weightage: 4, name: "4" },
            { weightage: 5, name: "5" },
            { weightage: 6, name: "6" },
            { weightage: 7, name: "7" },
            { weightage: 8, name: "8" },
            { weightage: 9, name: "9" },
            { weightage: 10, name: "10" }
          ]
        }
      }
      else if (QuesTypeTemplate === 10) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "This is a Statement!",
          options: []
        }
      }
      else if (QuesTypeTemplate === 11) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [
            { weightage: 1, name: "Yes" },
            { weightage: 0, name: "No" }
          ]
        }
      }
      else {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId: ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          options: [{ weightage: 1, name: "Option" }]
        }
      }
      //console.log('Question Created! ', Ques)
      this.props.dispatch(questionsActions.createQuestion(Ques));

      setTimeout(() => {
        this.setState({ questionTypeTemplate: "" })
      }, 1000)

    }


  }

  onPreviewPage = () => {
    let { currQuesEditId } = this.state;
    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {
      this.setState({
        showWelcomePage: false,
        showAddQuestion: false,
        showPreviewPage: true,
        showTranslatePage: false,
        showDesignPage: false,
        showLogicPage: false,
        showDashboardDesignPage: false

      });

    }
  }


  onTranslatePage = () => {
    this.setState({
      showWelcomePage: false,
      showAddQuestion: false,
      showPreviewPage: false,
      showTranslatePage: true,
      showDesignPage: false,
      showLogicPage: false,
      showDashboardDesignPage: false

    })
  }


  onQuestionPage = () => {
    this.setState({
      showWelcomePage: false,
      showPreviewPage: false,
      showAddQuestion: true,
      showTranslatePage: false,
      showDesignPage: false,
      showLogicPage: false,
      showDashboardDesignPage: false

    })
  }



  onWelcomePage = (screen) => {
    let { survey } = this.props;
    let { getSurveyData } = survey;
    let { currQuesEditId } = this.state;
    let data2 = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data2));

    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {

      if (screen === "welcome") {
        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: true,
          showTranslatePage: false,
          showDesignPage: false,
          showLogicPage: false,
          showDashboardDesignPage: false
        });
      }
      else if (screen === "design") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: false,
          showDesignPage: true,
          showLogicPage: false,
          showDashboardDesignPage: false
        });
      }
      else if (screen === "logic") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: false,
          showDesignPage: false,
          showLogicPage: true,
          showDashboardDesignPage: false
        });
      }
      else if (screen === "translate") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: true,
          showDesignPage: false,
          showLogicPage: false,
          showDashboardDesignPage: false
        });
      }
      else if (screen === "dashboardDesign") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: false,
          showDesignPage: false,
          showLogicPage: false,
          showDashboardDesignPage: true
        });

        let { dashQuesTypeRank } = this.state;
        let { getQuestionTypeListData } = this.props.survey;

        if (getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank) {
          dashQuesTypeRank = getSurveyData.designObj.componentRank;
          if (getQuestionTypeListData && getQuestionTypeListData.length > 0) {
            getQuestionTypeListData.forEach((item, index) => {
              if (dashQuesTypeRank && dashQuesTypeRank[item.templateId]) {
                // dashQuesTypeRank[item.templateId] = dashQuesTypeRank[item.templateId];
              }
              else {
                dashQuesTypeRank[item.templateId] = 1;
              }
            });
          }
        }
        else {
          if (getQuestionTypeListData && getQuestionTypeListData.length > 0) {
            getQuestionTypeListData.forEach((item, index) => {
              dashQuesTypeRank[item.templateId] = parseInt(index + 1);
            });
          }
        }
        this.setState({ dashQuesTypeRank });


      }

      let data = {
        "id": getSurveyData.id,
        "welcomeText": (getSurveyData.welcomeText) ? getSurveyData.welcomeText : "",
        "thankyouText": (getSurveyData.thankyouText) ? getSurveyData.thankyouText : "",
        "instructionText": (getSurveyData.instructionText) ? getSurveyData.instructionText : "",
        "isInstruction": (getSurveyData.isInstruction) ? getSurveyData.isInstruction : false,

        "disclaimer": (getSurveyData.disclaimer) ? getSurveyData.disclaimer : "",
        "isDisclaimer": (getSurveyData.isDisclaimer) ? getSurveyData.isDisclaimer : false,

        "textColor": (getSurveyData.textColor) ? getSurveyData.textColor : "#262626",
        "bgColor": (getSurveyData.bgColor) ? getSurveyData.bgColor : "#ffffff",
        "OptionColor1": (getSurveyData.OptionColor1) ? getSurveyData.OptionColor1 : "#3399ff",
        "OptionColor2": (getSurveyData.OptionColor2) ? getSurveyData.OptionColor2 : "#000",
        "OptionColor2x": (getSurveyData.OptionColor2x) ? getSurveyData.OptionColor2x : "#ffffff",
        "OptionColor3": (getSurveyData.OptionColor3) ? getSurveyData.OptionColor3 : "#33cc33",
        "buttonColor1": (getSurveyData.buttonColor1) ? getSurveyData.buttonColor1 : "#4d4d4d",
        "buttonColor2": (getSurveyData.buttonColor2) ? getSurveyData.buttonColor2 : "#ffffff",
        "headerColor": (getSurveyData.headerColor) ? getSurveyData.headerColor : "#2e7ef0",

        "bgType": (getSurveyData.bgType) ? getSurveyData.bgType : 1,

        "designTemplate": (getSurveyData.designTemplate) ? getSurveyData.designTemplate : "v4",


        "bgImage": (getSurveyData.bgImage) ? getSurveyData.bgImage : "",
        "logo": (getSurveyData.logo) ? getSurveyData.logo : "",
        "logo2": (getSurveyData.logo2) ? getSurveyData.logo2 : "",
        "parameterList": (getSurveyData && getSurveyData.parameterList) ? getSurveyData.parameterList : [],
        "orgList": (getSurveyData && getSurveyData.orgList) ? getSurveyData.orgList : [],
        "langList": (getSurveyData && getSurveyData.langList) ? getSurveyData.langList : [],
        "designObj": (getSurveyData && getSurveyData.designObj) ? getSurveyData.designObj : {},
        "parameterListUpdate": [],
        "orgListUpdate": [],
        "langListUpdate": []
      }

      //console.log('====>getSurveyData', data)

      if (getSurveyData && getSurveyData.parameterList && getSurveyData.parameterList.length > 0) {
        getSurveyData.parameterList.forEach((item) => {
          data["parameterListUpdate"].push(item.id)
        })
      }

      if (getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
        getSurveyData.orgList.forEach((item) => {
          data["orgListUpdate"].push(item.id)
        })
      }


      if (getSurveyData && getSurveyData.langList && getSurveyData.langList.length > 0) {
        getSurveyData.langList.forEach((item) => {
          data["langListUpdate"].push(item.id)
        })
      }

      this.setState({ welcomeFormData: data })
      // //////console.log(" xx-x xxx>>>: ",data)

      if (this.state.welcomeScreenModal) {
        this.setState({ welcomeScreenModal: false })
      } else {
        this.setState({ welcomeScreenModal: true })
      }


    }
  }


  saveAutoParameter = (isDeleteQ, Ques) => {
    let { questions, survey } = this.props;
    let { questionsList } = questions;
    let { getSurveyData } = survey;

    let saveParameterList = [];
    let saveOrgList = [];
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((item) => {
        if (item && item.parameterBucketId && item.parameterBucketId.id) {
          if (item.parameterBucketId.name === "OrganizationCore") {
            if (item && item.parameterDimensionId && item.parameterDimensionId.id) {
              let getIndex2 = saveOrgList.findIndex(prev => prev === item.parameterDimensionId.id);
              if (getIndex2 === -1) {
                saveOrgList.push(item.parameterDimensionId.id);
              }
            }
          }
          else {
            let getIndex = saveParameterList.findIndex(prev => prev === item.parameterBucketId.id);
            if (getIndex === -1) {
              saveParameterList.push(item.parameterBucketId.id);
            }
          }

        }
      });
    }
    if (isDeleteQ) {
      if (Ques && Ques.parameterBucketId && Ques.parameterBucketId.id) {
        if (Ques.parameterBucketId.name === "OrganizationCore") {
          if (Ques && Ques.parameterDimensionId && Ques.parameterDimensionId.id) {
            let getIndex3 = saveOrgList.findIndex(prev => prev === Ques.parameterDimensionId.id);
            saveOrgList.splice(getIndex3, 1);
          }
        }
        else {
          let getIndex4 = saveParameterList.findIndex(prev => prev === Ques.parameterBucketId.id);
          saveParameterList.splice(getIndex4, 1);
        }
      }
    }
    //////console.log("----------->saveParameterList---------------->", saveParameterList);


    let withJSdata1 = "";
    if (getSurveyData && getSurveyData.welcomeText) {
      withJSdata1 = getSurveyData.welcomeText.replace(/<script.*>.*<\/script>/ims, " ");
    }

    let withJSdata2 = "";
    if (getSurveyData && getSurveyData.thankyouText) {
      withJSdata2 = getSurveyData.thankyouText.replace(/<script.*>.*<\/script>/ims, " ");
    }

    let withJSdata3 = "";
    if (getSurveyData && getSurveyData.instructionText) {
      withJSdata3 = getSurveyData.instructionText.replace(/<script.*>.*<\/script>/ims, " ");
    }

    let withJSdataDisclaimer = "";
    if (getSurveyData && getSurveyData.disclaimer) {
      withJSdata3 = getSurveyData.disclaimer.replace(/<script.*>.*<\/script>/ims, " ");
    }

    let data = {
      "id": getSurveyData.id,
      "welcomeText": (withJSdata1) ? withJSdata1 : "",
      "thankyouText": (withJSdata2) ? withJSdata2 : "",
      "instructionText": (withJSdata3) ? withJSdata3 : "",
      "isInstruction": (getSurveyData.isInstruction) ? getSurveyData.isInstruction : false,

      "disclaimer": (withJSdataDisclaimer) ? withJSdataDisclaimer : "",
      "isDisclaimer": (getSurveyData.isDisclaimer) ? getSurveyData.isDisclaimer : false,

      "textColor": (getSurveyData.textColor) ? getSurveyData.textColor : "#262626",
      "bgColor": (getSurveyData.bgColor) ? getSurveyData.bgColor : "#ffffff",
      "OptionColor1": (getSurveyData.OptionColor1) ? getSurveyData.OptionColor1 : "#3399ff",
      "OptionColor2": (getSurveyData.OptionColor2) ? getSurveyData.OptionColor2 : "#000",
      "OptionColor2x": (getSurveyData.OptionColor2x) ? getSurveyData.OptionColor2x : "#ffffff",
      "OptionColor3": (getSurveyData.OptionColor3) ? getSurveyData.OptionColor3 : "#33cc33",
      "buttonColor1": (getSurveyData.buttonColor1) ? getSurveyData.buttonColor1 : "#4d4d4d",
      "buttonColor2": (getSurveyData.buttonColor2) ? getSurveyData.buttonColor2 : "#ffffff",
      "headerColor": (getSurveyData.headerColor) ? getSurveyData.headerColor : "#2e7ef0",

      "bgType": (getSurveyData.bgType) ? getSurveyData.bgType : 1,

      "designTemplate": (getSurveyData.designTemplate) ? getSurveyData.designTemplate : "v4",

      //       "designObj": (getSurveyData && getSurveyData.designObj) ? getSurveyData.designObj:{},


      "bgImage": (getSurveyData.bgImage) ? getSurveyData.bgImage : "",
      "logo": (getSurveyData.logo) ? getSurveyData.logo : "",
      "logo2": (getSurveyData.logo2) ? getSurveyData.logo2 : "",
      "parameterList": saveParameterList,
      "orgList": saveOrgList,
      "langList": getSurveyData.langList
    }
    let temp = {
      surveyId: this.props.match.params.id
    }
    //////console.log("saveWelcomePage>>> ", data);
    this.props.dispatch(surveyActions.updateSurveyWelcome(data, this.props, temp));
    this.props.dispatch(surveyActions.getSurveyById(temp));



    this.props.dispatch(surveyActions.autoChangeDashboardType(temp));

  }



  onSaveQues = (question) => {
    this.props.dispatch(questionsActions.updateQuestion(question, false));
    this.onTranslateQuestionSave(true, "customNPS");
    localStorage.removeItem('tempQuestion');

    this.setState({
      currQuesEditId: '',

      paraBucketName: '',
      paraDimensionName: ''

    });


  }


  onCopyQues = (question, surveyId) => {
    let reqData = {
      questionId: question.id
    }
    this.props.dispatch(questionsActions.copyQuestion(reqData, surveyId));
  }


  onEditQues = (question) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    localStorage.setItem('tempQuestion', JSON.stringify(question));

    this.setState({ currQuesEditId: question.id, showParameterModal: false });


    let { translQuestion } = this.state;
    if (true) {
      translQuestion['id'] = question.id;
      translQuestion['transl'] = {};






      if (question.templateId === 6 || question.templateId === 16) {

        if (question && question.transl) {
          translQuestion['transl'] = question.transl;

          if (!(question && question.transl && question.transl["customNPS"])) {
            translQuestion['transl']["customNPS"] = {};
          }


          let data = question && question.transl && question.transl["customNPS"] ? question.transl["customNPS"] : " ";

          translQuestion['transl']["customNPS"]['Ratehere'] = data && data.Ratehere ? data.Ratehere : "";
          translQuestion['transl']["customNPS"]['Extremelydissatisfied'] = data && data.Extremelydissatisfied ? data.Extremelydissatisfied : "";
          translQuestion['transl']["customNPS"]['Dissatisfied'] = data && data.Dissatisfied ? data.Dissatisfied : "";
          translQuestion['transl']["customNPS"]['Neither'] = data && data.Neither ? data.Neither : "";
          translQuestion['transl']["customNPS"]['Satisfied'] = data && data.Satisfied ? data.Satisfied : "";
          translQuestion['transl']["customNPS"]['Extremelysatisfied'] = data && data.Extremelysatisfied ? data.Extremelysatisfied : "";

        }
        else {
          translQuestion['transl'] = {};
          translQuestion['transl']["customNPS"] = {
            "Ratehere": "",
            "Extremelydissatisfied": "",
            "Dissatisfied": "",
            "Neither": "",
            "Satisfied": "",
            "Extremelysatisfied": "",
          }
        }

      }
      //     if(question.templateId===15){

      //       if(question && question.transl){
      //         translQuestion['transl'] = question.transl;

      //         if(!(question && question.transl && question.transl["customNPS"])){
      //           translQuestion['transl']["customNPS"] = {};
      //         }


      //         let data = question && question.transl && question.transl["customNPS"]? question.transl["customNPS"] : " ";

      //           translQuestion['transl']["customNPS"]['state'] = data && data.state ?data.state:"";
      //           translQuestion['transl']["customNPS"]['city'] = data && data.city ?data.city:"";

      //       }
      //       else{
      //         translQuestion['transl'] = {};
      //         translQuestion['transl']["customNPS"] = {
      //           "state":"",
      //           "city":""
      //         }
      //       }

      // }
      else if (question.templateId === 15) {

        if (question && question.transl) {
          translQuestion['transl'] = question.transl;

          if (!(question && question.transl && question.transl["customLocation"])) {
            translQuestion['transl']["customLocation"] = {};
          }


          let data = question && question.transl && question.transl["customLocation"] ? question.transl["customLocation"] : " ";

          translQuestion['transl']["customLocation"]['type'] = data && data.type ? data.type : "";

        }
        else {
          translQuestion['transl'] = {};
          translQuestion['transl']["customLocation"] = {
            "type": ""
          }
        }

      }
      else if (question.templateId === 1) {

        if (question && question.transl) {
          translQuestion['transl'] = question.transl;

          if (!(question && question.transl && question.transl["questionLogic"])) {
            translQuestion['transl']["questionLogic"] = {};
          }


          let data = question && question.transl && question.transl["questionLogic"] ? question.transl["questionLogic"] : " ";


          if (question && question.transl && question.transl["questionLogic"] && question.transl["questionLogic"]["id"]) {
            translQuestion['transl']["questionLogic"] = data;

          }
          else {
            translQuestion['transl'] = {};
            translQuestion['transl']["questionLogic"] = {
              "enable": false, "id": "", "answers": []
            };
          }

        }
        else {
          translQuestion['transl'] = {};
          translQuestion['transl']["questionLogic"] = {
            "enable": false, "id": "", "answers": []
          };
        }

      }






    }






    //-------------------- parameter loading ------------------------------

    if (question && question.parameterBucketId && question.parameterBucketId.id) {
      this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, question.id, question.parameterBucketId, 1));
    }
    if (question && question.parameterDimensionId && question.parameterDimensionId.id) {
      this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, question.id, question.parameterDimensionId, 2));
    }

    if (question && question.parameterBucketId && question.parameterBucketId.id) {
      this.props.dispatch(surveyActions.getParameterListbyParentId({ parentId: question.parameterBucketId.id }));
    }
  }

  onSaveTranslateWelcome = (isWelcome) => {
    if (isWelcome) {
      this.setState({ ShowTranslWelcome: false })
    }
    else {
      this.setState({ showTranslAttributes: false })
    }
    let { translSurvey } = this.state;


    let tempdate = {
      surveyId: this.props.match.params.id
    }

    //console.log('------>translSurvey: ', translSurvey)

    this.props.dispatch(questionsActions.translateSurvey(translSurvey, tempdate));

  }

  onTranslateWelcomePage = (surveyData, isWelcome, translShortName) => {

    let { translSurvey } = this.state;

    if (translShortName) {
      translSurvey['id'] = surveyData.id;
      translSurvey["transl"] = {}

      if (surveyData && surveyData.transl) {
        translSurvey["transl"] = surveyData.transl;

        if (!(surveyData && surveyData.transl && surveyData.transl[translShortName])) {
          translSurvey['transl'][translShortName] = {};
        }
        let data = surveyData && surveyData.transl && surveyData.transl[translShortName] ? surveyData.transl[translShortName] : " ";

        translSurvey["transl"][translShortName]['name'] = data && data.name ? data.name : "";
        translSurvey["transl"][translShortName]['desc'] = data && data.desc ? data.desc : "";

        translSurvey["transl"][translShortName]['welcomeText'] = data && data.welcomeText ? data.welcomeText : "";
        translSurvey["transl"][translShortName]['instructionText'] = data && data.instructionText ? data.instructionText : "";
        translSurvey["transl"][translShortName]['thankyouText'] = data && data.thankyouText ? data.thankyouText : "";

        translSurvey["transl"][translShortName]['StartButtonText'] = data && data.StartButtonText ? data.StartButtonText : "";
        translSurvey["transl"][translShortName]['ContinueButtonText'] = data && data.ContinueButtonText ? data.ContinueButtonText : "";
        translSurvey["transl"][translShortName]['SubmitButtonText'] = data && data.SubmitButtonText ? data.SubmitButtonText : "";
        translSurvey["transl"][translShortName]['okButtonText'] = data && data.okButtonText ? data.okButtonText : "";
        translSurvey["transl"][translShortName]['WriteHereText'] = data && data.WriteHereText ? data.WriteHereText : "";
        translSurvey["transl"][translShortName]['ExpiryText'] = data && data.ExpiryText ? data.ExpiryText : "";
        translSurvey["transl"][translShortName]['AlreadyText'] = data && data.AlreadyText ? data.AlreadyText : "";
        translSurvey["transl"][translShortName]['mandatoryText'] = data && data.mandatoryText ? data.mandatoryText : "";
        translSurvey["transl"][translShortName]['allmandatoryText'] = data && data.allmandatoryText ? data.allmandatoryText : "";
        translSurvey["transl"][translShortName]['completedText'] = data && data.completedText ? data.completedText : "";
        translSurvey["transl"][translShortName]['instructionLabel'] = data && data.instructionLabel ? data.instructionLabel : "";

      }
      else {
        translSurvey["transl"] = {}
        translSurvey['transl'][translShortName] = {};
        translSurvey["transl"][translShortName]['name'] = "";
        translSurvey["transl"][translShortName]['desc'] = "";

        translSurvey["transl"][translShortName]['welcomeText'] = "";
        translSurvey["transl"][translShortName]['instructionText'] = "";
        translSurvey["transl"][translShortName]['thankyouText'] = "";

        translSurvey["transl"][translShortName]['StartButtonText'] = "";
        translSurvey["transl"][translShortName]['ContinueButtonText'] = "";
        translSurvey["transl"][translShortName]['SubmitButtonText'] = "";
        translSurvey["transl"][translShortName]['okButtonText'] = "";
        translSurvey["transl"][translShortName]['WriteHereText'] = "";
        translSurvey["transl"][translShortName]['ExpiryText'] = "";
        translSurvey["transl"][translShortName]['AlreadyText'] = "";

        translSurvey["transl"][translShortName]['mandatoryText'] = "";
        translSurvey["transl"][translShortName]['allmandatoryText'] = "";
        translSurvey["transl"][translShortName]['completedText'] = "";
        translSurvey["transl"][translShortName]['instructionLabel'] = "";
      }

      this.setState({ translSurvey })

      if (isWelcome) {
        this.setState({ ShowTranslWelcome: true, showTranslAttributes: false })
      }
      else {
        this.setState({ showTranslAttributes: true, ShowTranslWelcome: false })
      }

    }



  }




  onTranslateQues = (question, translShortName) => {


    let { translQuestion, translSubQuestionsMatrix } = this.state;

    //console.log('question: ', question)

    if (translShortName) {
      translQuestion['id'] = question.id;
      translQuestion['transl'] = {};

      if (question && question.transl) {
        translQuestion['transl'] = question.transl;

        if (!(question && question.transl && question.transl[translShortName])) {
          translQuestion['transl'][translShortName] = {};
        }


        let data = question && question.transl && question.transl[translShortName] ? question.transl[translShortName] : " ";

        translQuestion['transl'][translShortName]['name'] = data && data.name ? data.name : "";
        translQuestion['transl'][translShortName]['optionsList'] = data && data.optionsList ? data.optionsList : {};
        translQuestion['transl'][translShortName]['headingList'] = data && data.headingList ? data.headingList : {};

      }
      else {
        translQuestion['transl'] = {};
        translQuestion['transl'][translShortName] = {
          "name": "", optionsList: {}, headingList: {}
        }
      }


      // for matrix ------------------------------------------------------------------------
      if (question.templateId === 13 && (!question.parentId)) {
        if (question && question.SubQuestionList && question.SubQuestionList.length > 0) {
          question.SubQuestionList.forEach((quesSub, index) => {

            translSubQuestionsMatrix[quesSub.id] = {};

            translSubQuestionsMatrix[quesSub.id]['id'] = quesSub.id;

            translSubQuestionsMatrix[quesSub.id]['transl'] = {};

            if (quesSub && quesSub.transl) {
              translSubQuestionsMatrix[quesSub.id]['transl'] = quesSub.transl;

              if (!(quesSub && quesSub.transl && quesSub.transl[translShortName])) {
                translSubQuestionsMatrix[quesSub.id]['transl'][translShortName] = {};
              }


              let data = quesSub && quesSub.transl && quesSub.transl[translShortName] ? quesSub.transl[translShortName] : " ";

              translSubQuestionsMatrix[quesSub.id]['transl'][translShortName]['name'] = data && data.name ? data.name : "";

            }
            else {
              translSubQuestionsMatrix[quesSub.id]['transl'] = {};
              translSubQuestionsMatrix[quesSub.id]['transl'][translShortName] = {
                "name": ""
              }
            }

          });
        }


      }



      //------------------------------------------------------------------------
      this.setState({ currQuesEditId: question.id, translQuestion, translSubQuestionsMatrix });

      //console.log('translQuestion: ', translQuestion)
      //console.log('x--------->translSubQuestionsMatrix: ', translSubQuestionsMatrix)
      //console.log('x--------->translSubQuestionsMatrix: ', translSubQuestionsMatrix)

    }





  }






  innerEdit = (question) => {
    if ((question && question.isNonEdit ? false : true)) {
      this.onEditQues(question);
    }
  }


  onEditTranslateQues = (question) => {
    this.setState({ currQuesTranslEditId: question.id });
  }

  onDeleteConfirm = (question) => {
    //console.log('question: ', question)
    let data = {
      id: question.id,
      surveyId: question.surveyId
    }

    this.setState({ CurrentQuestionData: data, CurrentQuestionName: question.name });

  }


  onDeleteQues = () => {
    let { CurrentQuestionData } = this.state;
    // this.saveAutoParameter(true,CurrentQuestionData)
    this.handleConfirmDeleteQuesClose();
    this.props.dispatch(questionsActions.deleteQuestion(CurrentQuestionData))

  }

  handleConfirmDeleteQuesClose = () => {
    this.setState({ CurrentQuestionData: '', CurrentQuestionName: '' });
  }



  onClickQuesUp = (ques1, index, surveyId) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    // //////console.log("working:onClickUp: ",ques1,index,'==>',surveyId)

    let ques2 = ''
    if (questionsList && questionsList.length > 0 && index) {
      ques2 = questionsList[index - 1].id;
    }

    if (ques1 && ques2 && surveyId) {
      let data = {
        idA: ques1,
        idB: ques2
      }
      //console.log('data idA, IdB: ', data, surveyId)
      this.props.dispatch(questionsActions.updateQuestionRenk(data, surveyId));
    }

  }

  onClickQuesDown = (ques1, index, surveyId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    let ques2 = ''
    if (questionsList && questionsList.length > 0 && (index !== questionsList.length - 1)) {
      ques2 = questionsList[index + 1].id;
    }


    if (ques1 && ques2 && surveyId) {
      let data = {
        idA: ques1,
        idB: ques2
      }
      this.props.dispatch(questionsActions.updateQuestionRenk(data, surveyId));
    }

  }




  handleQuestionInput = (value, quesId, isVariable) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.handleNameQuestion(questionsList, quesId, value, isVariable));
  }


  handleQuesSecInput = (value, quesId, isVariable) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    if ((value <= 300)) {
      this.props.dispatch(questionsActions.handleQuestionTime(questionsList, quesId, value, isVariable));
    }
  }



  onQuesMandatory = (isMandatory, quesId) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.handleMandatoryQuestion(questionsList, quesId, !isMandatory));
  }

  handleOptionInput = (value, quesId, optionId, type) => {

    let { questions } = this.props;
    let { questionsList } = questions;

    if (type === "bot") {
      this.props.dispatch(questionsActions.handleBotOption(questionsList, quesId, optionId, value));
    }
    else if (type === "feedback") {
      this.props.dispatch(questionsActions.handleFeedbackOption(questionsList, quesId, optionId, value));
    }
    else {
      this.props.dispatch(questionsActions.handleNameOption(questionsList, quesId, optionId, value));
    }


  }


  handleQuestionDesign = (quesId, value) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.handlequestionDesign(questionsList, quesId, value));

  }

  handleOptionHeadingInput = (value, quesId, optionId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.handleNameOptionHeading(questionsList, quesId, optionId, value));

  }





  handleAddOptionHeading = (check) => {
    this.setState({ showOptionHeading: check })
  }


  handleWeightageInput = (event, quesId, optionId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.handleNameWeightage(questionsList, quesId, optionId, event.target.value));

  }

  onAddNewOption = (ques) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.addOption(questionsList, ques));

  }

  onDeleteOption = (quesId, optionId) => {

    //console.log('onDeleteOption: ', quesId)


    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.deleteOption(questionsList, quesId, optionId));
  }


  handleWelcomeTextInput = (value, type) => {
    let { welcomeFormData } = this.state;
    if (type === 2) {
      welcomeFormData['welcomeText'] = welcomeFormData['welcomeText'].replace("</p>", value + "</p>");
    } else {
      welcomeFormData['welcomeText'] = value;
    }
    this.setState({ welcomeFormData });
  }
  handleInstructionTextInput = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['instructionText'] = value;
    this.setState({ welcomeFormData });
  }

  handleDisclaimerTextInput = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['disclaimer'] = value;
    this.setState({ welcomeFormData });
  }



  handleThankyouTextInput = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['thankyouText'] = value;
    this.setState({ welcomeFormData });
  }


  handleSurveyTemplate = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['designTemplate'] = value;
    this.setState({ welcomeFormData });
  }


  handleLogoRemove = (type) => {
    let { welcomeFormData } = this.state;
    if (type === 2) {
      welcomeFormData['logo2'] = "";
      this.props.dispatch(userActions.uploadImageLogo2(null));
      this.setState({ welcomeFormData, imageWebLinkLogo2: "", selectedFileLogo2: null });
    }
    else {
      welcomeFormData['logo'] = "";
      this.props.dispatch(userActions.uploadImageLogo(null));
      this.setState({ welcomeFormData, imageWebLinkLogo: "", selectedFileLogo1: null });
    }
  }


  handleImageRemove = () => {
    let { welcomeFormData } = this.state;
    welcomeFormData['bgImage'] = "";
    welcomeFormData['bgType'] = 1;
    this.setState({ welcomeFormData, imageWebLink: "" });
  }


  handleSurveyParameterList = (event, val) => {
    let { welcomeFormData } = this.state;

    if (val.option) {
      welcomeFormData.parameterListUpdate.push(val.option.id);
      this.setState({ welcomeFormData });
    }
    else if (val.removedValue) {
      welcomeFormData.parameterListUpdate.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          welcomeFormData.parameterListUpdate.splice(delIndex, 1);
        }
      })


      this.setState({ welcomeFormData });
    }
  }


  handleSurveyorgList = (event, val) => {
    let { welcomeFormData } = this.state;

    if (val.option) {
      welcomeFormData.orgListUpdate.push(val.option.id);
      this.setState({ welcomeFormData });
    }
    else if (val.removedValue) {
      welcomeFormData.orgListUpdate.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          welcomeFormData.orgListUpdate.splice(delIndex, 1);
        }
      })


      this.setState({ welcomeFormData });
    }
  }



  handleSurveyLangList = (event, val) => {
    let { welcomeFormData } = this.state;

    if (val.option) {
      welcomeFormData.langListUpdate.push(val.option.id);
      this.setState({ welcomeFormData });
    }
    else if (val.removedValue) {
      welcomeFormData.langListUpdate.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          welcomeFormData.langListUpdate.splice(delIndex, 1);
        }
      })


      this.setState({ welcomeFormData });
    }
  }


  handleChangetextColor = (color) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['textColor'] = color.hex;
    this.setState({ welcomeFormData });
  };

  handleChangebgColor = (color) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['bgColor'] = color.hex;
    this.setState({ welcomeFormData });
  };

  handlebgType = (data) => {
    //console.log('handlebgColorImage: ', data)
    let { welcomeFormData } = this.state;
    welcomeFormData['bgType'] = parseInt(data);
    this.setState({ welcomeFormData });
  }


  handleColorCode = (item) => {
    this.setState({ selectColorType: item.value });
  }


  handleChangeColorValue = (color) => {
    let { selectColorType, welcomeFormData } = this.state;
    welcomeFormData[selectColorType] = color.hex;
    this.setState({ welcomeFormData });
  }



  // -----------------BACKGROUND IMAGE-------------------------------------------

  handleFile = (event) => {

    const files = event.target.files;

    if (files && files[0]) {
      let fileName = files && files[0] && files[0].name ? files[0].name : "";
      let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
      let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
      if ((extName1 === "png") || (extName2 === "jpeg") || (extName2 === "jpg")) {

        this.setState({ selectedFile: files[0] }, () => {
          //////console.log("this.state.file", this.state.selectedFile);
          this.props.dispatch(userActions.uploadImage(files[0]));
          this.setState({ selectedFile: null, imageUploadError: "" });

          let { welcomeFormData } = this.state;
          welcomeFormData['bgType'] = 2;
          this.setState({ welcomeFormData });

        });

      }
      else {
        this.setState({ imageUploadError: "Please upload png or jpeg!" });
      }

    }
    else {
      this.setState({ imageUploadError: "Please upload png or jpeg!" });
    }




    // this.setState({ selectedFile: event.target.files[0] });
    // if (event.target.files[0]) {
    //   this.props.dispatch(userActions.uploadImage(event.target.files[0]));
    //   this.setState({ selectedFile: null });
    // }

    // let { welcomeFormData } = this.state;
    // welcomeFormData['bgType'] = 2;
    // this.setState({ welcomeFormData });



  }

  // handleUpload=()=>{

  //   if(this.state.selectedFile){
  //    this.props.dispatch(userActions.uploadImage(this.state.selectedFile));
  //    this.setState({ selectedFile: null });
  //   }


  //  }

  handleImageRemove = () => {

    this.props.dispatch(userActions.uploadImage(null));
    this.setState({ selectedFile: null, imageUploadError: "" });

    let { welcomeFormData } = this.state;
    welcomeFormData['bgType'] = 1;
    this.setState({ welcomeFormData, imageWebLink: "" });



    // let { welcomeFormData } = this.state;
    // welcomeFormData['bgImage'] = "";
    // this.setState({ welcomeFormData, imageWebLink: "" });
  }


  // -----------------LOGO-------------------------------------------


  handleFileLogo = (event) => {
    const files = event.target.files;

    if (files && files[0]) {
      let fileName = files && files[0] && files[0].name ? files[0].name : "";
      let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
      let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
      if ((extName1 === "png") || (extName2 === "jpeg")) {

        this.setState({ selectedFileLogo: files[0] }, () => {
          //////console.log("this.state.file", this.state.selectedFileLogo);
          this.props.dispatch(userActions.uploadImageLogo(files[0]));
          this.setState({ selectedFileLogo: null, logoUploadError: "" });
        });

      }
      else {
        this.setState({ logoUploadError: "Please upload png or jpeg!" });
      }

    }
    else {
      this.setState({ logoUploadError: "Please upload png or jpeg!" });
    }


    // this.setState({ selectedFileLogo: event.target.files[0] });
    // if (event.target.files[0]) {
    //   this.props.dispatch(userActions.uploadImageLogo(event.target.files[0]));
    //   this.setState({ selectedFileLogo: null });
    // }


  }

  handleFileLogo2 = (event) => {

    const files = event.target.files;

    if (files && files[0]) {
      let fileName = files && files[0] && files[0].name ? files[0].name : "";
      let extName1 = fileName && fileName.substr(fileName.length - 3) ? fileName.substr(fileName.length - 3) : "";
      let extName2 = fileName && fileName.substr(fileName.length - 4) ? fileName.substr(fileName.length - 4) : "";
      if ((extName1 === "png") || (extName2 === "jpeg")) {

        this.setState({ selectedFileLogo2: files[0] }, () => {
          //////console.log("this.state.file", this.state.selectedFileLogo2);
          this.props.dispatch(userActions.uploadImageLogo2(files[0]));
          this.setState({ selectedFileLogo2: null, logoUploadError: "" });
        });

      }
      else {
        this.setState({ logoUploadError: "Please upload png or jpeg!" });
      }

    }
    else {
      this.setState({ logoUploadError: "Please upload png or jpeg!" });
    }


    // this.setState({ selectedFileLogo2: event.target.files[0] });
    // if (event.target.files[0]) {
    //   this.props.dispatch(userActions.uploadImageLogo2(event.target.files[0]));
    //   this.setState({ selectedFileLogo2: null });
    // }

  }

  // handleUploadLogo=()=>{

  //  if(this.state.selectedFileLogo){
  //   this.props.dispatch(userActions.uploadImageLogo(this.state.selectedFileLogo));
  //   this.setState({ selectedFileLogo: null });
  //  }

  // }

  // handleLogoRemove=()=>{
  //   let { welcomeFormData } = this.state;
  //   welcomeFormData['logo'] = "";
  //   this.setState({ welcomeFormData, imageWebLinkLogo:""  }); 
  // }


  // ------------------------------------------------------------

  saveWelcomePage = () => {

    let tempA = {
      surveyId: this.props.match.params.id
    }

    this.props.dispatch(surveyActions.autoChangeDashboardType(tempA));



    let { welcomeFormData, showDashboardDesignPage, dashQuesTypeRank } = this.state;

    if (showDashboardDesignPage) {
      let data = {
        "id": this.props.match.params.id,
        "designObj": {
          "componentRank": dashQuesTypeRank
        }
      }
      let temp = {
        surveyId: this.props.match.params.id
      }
      this.props.dispatch(surveyActions.updateSurveyDesign(data, temp));
    }

    let sparameterListUpdate = []
    if (welcomeFormData && welcomeFormData.parameterListUpdate && welcomeFormData.parameterListUpdate.length > 0) {
      welcomeFormData.parameterListUpdate.forEach((ele) => {
        if (ele) {
          sparameterListUpdate.push(ele)
        }
      })
    }

    let sorgListUpdate = []
    if (welcomeFormData && welcomeFormData.orgListUpdate && welcomeFormData.orgListUpdate.length > 0) {
      welcomeFormData.orgListUpdate.forEach((ele) => {
        if (ele) {
          sorgListUpdate.push(ele)
        }
      })
    }

    let data = {
      "id": welcomeFormData.id,
      "welcomeText": welcomeFormData.welcomeText,
      "thankyouText": welcomeFormData.thankyouText,
      "instructionText": welcomeFormData.instructionText,
      "isInstruction": welcomeFormData.isInstruction,

      "disclaimer": welcomeFormData.disclaimer,
      "isDisclaimer": welcomeFormData.isDisclaimer,

      "textColor": welcomeFormData.textColor,
      "bgColor": welcomeFormData.bgColor,
      "OptionColor1": welcomeFormData.OptionColor1,
      "OptionColor2": welcomeFormData.OptionColor2,
      "OptionColor2x": welcomeFormData.OptionColor2x,
      "OptionColor3": welcomeFormData.OptionColor3,
      "buttonColor1": welcomeFormData.buttonColor1,
      "buttonColor2": welcomeFormData.buttonColor2,
      "headerColor": welcomeFormData.headerColor,

      "designTemplate": welcomeFormData.designTemplate,


      "bgType": welcomeFormData.bgType,
      "bgImage": (this.state.imageWebLink) ? this.state.imageWebLink : welcomeFormData.bgImage,
      "logo": (this.state.imageWebLinkLogo) ? this.state.imageWebLinkLogo : welcomeFormData.logo,
      "logo2": (this.state.imageWebLinkLogo2) ? this.state.imageWebLinkLogo2 : welcomeFormData.logo2,
      "parameterList": sparameterListUpdate,
      "orgList": sorgListUpdate,
      // "designObj": welcomeFormData.designObj,
      "langList": welcomeFormData.langListUpdate
    }
    let temp = {
      surveyId: this.props.match.params.id
    }
    //////console.log("saveWelcomePage>>> ", data);
    this.props.dispatch(surveyActions.updateSurveyWelcome(data, this.props, temp));





  }



  handleParameterBucket = (check) => {
    this.setState({
      showBucketDropdown: !check,
      showDimensionDropdown: false
    })
  }

  handleParameterDimension = (check) => {
    this.setState({
      showDimensionDropdown: !check,
      showBucketDropdown: false
    })
  }

  handleParameterQuestion = (para, quesId, isEmptyDimen) => {

    let { questions } = this.props;
    let { questionsList } = questions;



    if (isEmptyDimen === 1 || isEmptyDimen === 2) {
      this.setState({
        showBucketDropdown: false,
        showDimensionDropdown: false
      })


      //RefreshDimension
      let tempparameter = {
        "id": "",
        "name": ""
      }
      if (isEmptyDimen === 1) {
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 1));
        this.setState({ paraBucketName: "" });
      }
      else if (isEmptyDimen === 2) {

        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 1));
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 2));
        this.setState({ paraBucketName: "", paraDimensionName: "" });
      }


    }
    else {

      this.setState({
        showBucketDropdown: false,
        showDimensionDropdown: false
      })


      if (para.parameterTypeLevel === 1) {
        let parameter = {
          "id": para.id,
          "name": para.name
        }
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, parameter, 1));
        this.setState({ paraBucketName: para.name });
        this.props.dispatch(surveyActions.getParameterListbyParentId({ parentId: para.id }));

        //RefreshDimension
        let tempparameter = {
          "id": "",
          "name": ""
        }
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 2));
        this.setState({ paraDimensionName: "" });

      }
      else if (para.parameterTypeLevel === 2) {
        let parameter = {
          "id": para.id,
          "name": para.name
        }
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, parameter, 2));
        this.setState({ paraDimensionName: para.name });
      }

    }



  }

  onPublishSurvey = () => {
    let { currQuesEditId } = this.state;

    let { getSurveyData } = this.props.survey;

    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {
      let data = {
        id: this.props.match.params.id
      }

      if (getSurveyData && getSurveyData.surveyModule && (getSurveyData.surveyModule === 3)) {
        this.props.history.push(`/app/publishfeedback/${data.id}/1`)

      } else if (getSurveyData && getSurveyData.surveyModule && (getSurveyData.surveyModule === 2)) {
        this.props.history.push(`/app/publishsense/${data.id}`)
      }
      else if (getSurveyData && getSurveyData.surveyModule && (getSurveyData.surveyModule === 4)) {
        this.props.history.push(`/app/publishpulse/${data.id}`)
      } else if (getSurveyData && getSurveyData.surveyModule && (getSurveyData.surveyModule === 6)) {
        this.props.history.push(`/app/publishassessment/${data.id}`)
      } else {
        this.props.history.push(`/app/publish/${data.id}`);
      }
    }
  }


  handleExpiryTime = (e, SurveyisExpire, SurveyexpireTime) => {
    let { expiryformData } = this.state;
    expiryformData[e.target.name] = e.target.value;
    this.setState({ expiryformData })

    let surveyId = this.props.match.params.id;

    let data = {}

    if (e.target.value) {
      data = {
        "id": surveyId,
        "isExpire": SurveyisExpire,
        "expireTime": e.target.value
      };
    }
    else {
      data = {
        "id": surveyId,
        "isExpire": SurveyisExpire,
        "expireTime": moment.unix(SurveyexpireTime / 1000).format("YYYY-MM-DD")
      };
    }

    this.props.dispatch(surveyActions.setExpireSurvey(data, surveyId));
  }

  isExpired = (check, SurveyexpireTime) => {
    let { expiryformData } = this.state;
    expiryformData["isExpire"] = !check
    this.setState({ expiryformData })

    let surveyId = this.props.match.params.id;
    let data = {
      "id": surveyId,
      "isExpire": !check,
      "expireTime": SurveyexpireTime
    };
    //console.log('SurveyexpireTime: ', SurveyexpireTime)
    this.props.dispatch(surveyActions.setExpireSurvey(data, surveyId));


  }



  submitExpiry = () => {
    let { expiryformData } = this.state;
    let surveyId = this.props.match.params.id;
    let data = {
      "id": surveyId,
      "isExpire": expiryformData.isExpire,
      "expireTime": expiryformData.expireTime
    };
    this.props.dispatch(surveyActions.setExpireSurvey(data));
  }


  updateSurvey = () => {
    let { surveyUpdateFormData } = this.state;
    //console.log('-------------------------------->', surveyUpdateFormData)

    let data = {
      id: surveyUpdateFormData.id,
      name: surveyUpdateFormData.name,
      desc: surveyUpdateFormData.desc,
      templateId: surveyUpdateFormData.templateId,


      // companyId:"5f9aaf951be7e064d18f6e6a"
    }
    let tempdata = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.updateSurvey(data, tempdata));
    this.setState({ showEditSurveyName: false })
  }

  handleUpdateSurveyNameInput = (e) => {
    let { surveyUpdateFormData } = this.state;
    surveyUpdateFormData["name"] = e.target.value;
    this.setState({ surveyUpdateFormData });

    //console.log('surveyUpdateFormData', e.target.value)
  }

  handleEditSurveyName = (getSurveyData) => {
    let { currQuesEditId } = this.state;
    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {
      //console.log('getSurveyData', getSurveyData)
      let data = {
        id: getSurveyData.id,
        name: getSurveyData.name,
        desc: getSurveyData.desc,
        templateId: getSurveyData.templateId,
      }
      this.setState({ surveyUpdateFormData: data, showEditSurveyName: true })
    }
  }

  handleInstructionCheck = (check) => {
    let { welcomeFormData } = this.state;
    welcomeFormData["isInstruction"] = !check;
    this.setState({ welcomeFormData });
  }

  handleDisclaimerCheck = (check) => {
    let { welcomeFormData } = this.state;
    welcomeFormData["isDisclaimer"] = !check;
    this.setState({ welcomeFormData });
  }

  onParamaterModal = (value) => {
    this.setState({ showParameterModal: value, showDimensionDropdown: false, showBucketDropdown: false });
  }

  handleQuesCancel = (value) => {
    let localData = localStorage.getItem('tempQuestion');
    let cryptedText = localData ? JSON.parse(localData) : "";
    let bytes = cryptedText ? CryptoJS.AES.decrypt(cryptedText, "secretkey123") : "";
    let tempSaveQues = "";

    try {
      tempSaveQues = bytes ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : "";
    } catch (error) {
      tempSaveQues = ""
    }

    if (tempSaveQues) {
      this.onSaveQues(tempSaveQues);
    }
    this.setState({ currQuesEditId: "" });
  }


  handleOpenIndexFilter = (check) => {
    this.setState({ showIndexFilter: check });
  }

  handleSelectIndexFilter = (para, isempty) => {
    if (isempty) {
      this.setState({ selectedIndexFilterValue: "" });
    }
    else {
      if (this.state.selectedIndexFilterValue && this.state.selectedIndexFilterValue.id === para.id) {
        this.setState({ selectedIndexFilterValue: "" });
      }
      else {
        this.setState({ selectedIndexFilterValue: para });
      }
    }

  }


  onCreateNewBucket = (check, question, type) => {
    this.setState({ showCreateParameterModal: check });
    this.setState({
      showBucketDropdown: false,
      showDimensionDropdown: false
    });

    if (check) {

      if (type === "bucket") {
        this.setState({ isBucket: true })

        let { dashboard } = this.props;
        let { getParameterTypeListData } = dashboard;
        let dataParaType = { "id": "" }
        if (getParameterTypeListData && getParameterTypeListData.length > 0) {
          let getIndex = getParameterTypeListData.findIndex(prev => prev.level === 1);
          dataParaType = getParameterTypeListData[getIndex];
        }

        this.props.dispatch(surveyActions.getParameterLibraryListbyParameterType({
          "parameterTypeId": dataParaType.id
        }));


      }
      else if (type === "dimension") {
        let bucketId = (question && question.parameterBucketId && question.parameterBucketId.id) ? question.parameterBucketId.id : "";
        if (bucketId) {
          this.setState({ isBucket: false, CurrBucketId: bucketId })
        }
      }

    }
    else {

      this.setState({ CrrParaLibCollection: [], CurrBucketId: "", isBucket: false });


    }








  }



  QuestionComponent = (question, quesindex, currQuesEditId, SurveyTemplateId, GetParameterLabel, translShortName, dashType1Check, surveyModuleNo) => {


    let { crrQuestionDesignObj, showBucketDropdown, showDimensionDropdown, paraBucketName, paraDimensionName, showTranslatePage } = this.state;
    let { survey, questions } = this.props;
    let { questionsList } = questions;
    let { parameterList, getParameterListbyParentData, getSurveyData } = survey;





    switch (question.templateId) {
      case 1: return (
        <SingleChoice
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}

          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          questionsList={questionsList}
          handleLogicQuestionOrOption={this.handleLogicQuestionOrOption}
          onCreateNewBucket={this.onCreateNewBucket}
          dashType1Check={dashType1Check}
          surveyModuleNo={surveyModuleNo}
          surveyDetails={getSurveyData}
          isPackage={getSurveyData && getSurveyData.isPackage}
          handleQuesSecInput={this.handleQuesSecInput}
          handleDMGModal={this.handleDMGModal}

        />)
      case 21: return (
        <DMGSingleChoice
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}

          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          questionsList={questionsList}
          handleLogicQuestionOrOption={this.handleLogicQuestionOrOption}
          onCreateNewBucket={this.onCreateNewBucket}
          dashType1Check={dashType1Check}
          surveyModuleNo={surveyModuleNo}
          surveyDetails={getSurveyData}
          isPackage={getSurveyData && getSurveyData.isPackage}
          handleQuesSecInput={this.handleQuesSecInput}
          handleDMGModal={this.handleDMGModal}

        />)
      case 2: return (
        <MultipleChoice
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)
      case 3: return (
        <TextBox
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          onCreateNewBucket={this.onCreateNewBucket}

          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}


        />)
      case 23: return (
        <DMGTextBox
          handleDMGModal={this.handleDMGModal}
          question={question}
          handleNudgeModal={this.handleNudgeModal}
          quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          onCreateNewBucket={this.onCreateNewBucket}

          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}


        />)
      case 4: return (
        <MultilineText
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          onCreateNewBucket={this.onCreateNewBucket}

          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}


        />)
      case 5: return (
        <RatingScale
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

          dashType1Check={dashType1Check}
          crrQuestionDesignObj={crrQuestionDesignObj}
          surveyDetails={getSurveyData}

          handleQuestionDesign={this.handleQuestionDesign}

        />)
      case 6: return (
        <NPS
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          handleNPSLable={this.handleNPSLable}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

          handleQuesSecInput={this.handleQuesSecInput}


        />)
      case 22: return (
        <DMGNPS
        handleDMGModal={this.handleDMGModal}
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          handleNPSLable={this.handleNPSLable}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

          handleQuesSecInput={this.handleQuesSecInput}


        />)
      case 16: return (
        <NPSV2
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          handleNPSLable={this.handleNPSLable}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

          handleQuesSecInput={this.handleQuesSecInput}


        />)

      case 7: return (
        <Email
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)
      case 8: return (
        <Number
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)
      case 9: return (
        <Dropdown
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)
      case 10: return (
        <Statement
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          surveyDetails={getSurveyData}


          dashType1Check={dashType1Check}

        />)
      case 11: return (
        <YesNo
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)
      case 12: return (
        <Date

          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)
      case 13: return (
        <Matrix
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}

          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          onDeleteConfirm={this.onDeleteConfirm}
          handleInputMatrixQues={this.handleInputMatrixQues}
          handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
          addMatrixQFn={this.addMatrixQFn}

          handleAddOptionHeading={this.handleAddOptionHeading}
          showOptionHeading={this.state.showOptionHeading}
          handleOptionHeadingInput={this.handleOptionHeadingInput}
          handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}
          surveyDetails={getSurveyData}

          dashType1Check={dashType1Check}

        />)

      case 14: return (
        <GroupQuestions
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}

          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          onDeleteConfirm={this.onDeleteConfirm}
          handleInputMatrixQues={this.handleInputMatrixQues}
          handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
          addMatrixQFn={this.addMatrixQFn}

          handleAddOptionHeading={this.handleAddOptionHeading}
          showOptionHeading={this.state.showOptionHeading}
          handleOptionHeadingInput={this.handleOptionHeadingInput}
          handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}

          handleQuestionTypeGroup={this.handleQuestionTypeGroup}
          handleAddQuestion={this.handleAddQuestion}
          questionTypeTemplateGroup={this.state.questionTypeTemplateGroup}

          dashType1Check={dashType1Check}
          surveyDetails={getSurveyData}

        />)

      case 15: return (
        <LocationQuestions
          question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          handleLocationLable={this.handleLocationLable}

          dashType1Check={dashType1Check}
          surveyDetails={getSurveyData}

        />)

      default: return null

    }
  }


  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });

  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }

  onPreviewFullPage = () => {
    let surveyId = this.props.match.params.id;
    this.props.dispatch(questionsActions.emptyReducer());

    if (surveyId) {
      this.props.history.push(`/app/preview/${surveyId}`)
    }


  }



  onLogout = () => {
    //console.log('logout')
    this.props.dispatch(userActions.logout());
  }


  handleLanguage = (value) => {

    if (!this.state.editId) {
      if (this.state.language === value) {
        this.setState({ language: '' });
      }
      else {
        this.setState({ language: value });
      }
    }

  }



  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }


  closeQuestionRestrictionModal = () => {
    this.setState({ showPleaseSaveQuesModal: false });
  }




  onQuestionLibrary = () => {

    let data5 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10000
    }
    this.props.dispatch(surveyActions.getQuestionLibraryList(data5));

    this.setState({ showQuestionLibraryModal: true });
  }

  handleQuestionLibraryModalClose = () => {
    this.setState({ showQuestionLibraryModal: false, QuestionLibraryFinalList: [] });
  }


  onSelectQLQue = (question) => {
    let { QuestionLibraryFinalList } = this.state;

    let getIndex = QuestionLibraryFinalList.findIndex(prev => prev === question.id);
    if (getIndex === -1) {
      QuestionLibraryFinalList.push(question.id)
    }
    else {
      QuestionLibraryFinalList.splice(getIndex, 1);
    }

    this.setState({ QuestionLibraryFinalList });
  }

  SubmitOfQuesLibList = () => {
    let { QuestionLibraryFinalList } = this.state;
    let data = {
      surveyId: this.props.match.params.id,
      questionList: QuestionLibraryFinalList
    }
    this.props.dispatch(questionsActions.copyFromQuestionLibrary(data));
    this.setState({ showQuestionLibraryModal: false, QuestionLibraryFinalList: [], selectedIndexFilterValue: "" });
  }


  handleTranlLanguage = (value, translShortName) => {
    if (!this.state.currQuesEditId && !this.state.ShowTranslWelcome) {
      if (translShortName === value) {
        this.setState({ CurrTranslShortName: '' });
      }
      else {
        this.setState({ CurrTranslShortName: value });
      }
    }

  }


  handleTranslateQuestion = (translQuesNames, quesId, translShortName) => {
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName]['name'] = translQuesNames;
    this.setState({ translQuestion });
  }

  handleInputTranslateMatrixQues = (translQuesNames, quesId, SubQues, translShortName) => {
    let { translSubQuestionsMatrix } = this.state;
    // translSubQuestionsMatrix[SubQues.id]['id'] = quesId;
    translSubQuestionsMatrix[SubQues.id]['transl'][translShortName]['name'] = translQuesNames;
    this.setState({ translSubQuestionsMatrix });
  }

  handleLocationLable = (translOptionName, quesId, optId, translShortName) => {
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName][optId] = translOptionName;
    this.setState({ translQuestion });

  }


  handleNPSLable = (translOptionName, quesId, optId, translShortName) => {
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName][optId] = translOptionName;
    this.setState({ translQuestion });
  }


  handleTranslateOption = (translOptionName, quesId, optId, translShortName) => {
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName]['optionsList'][optId] = translOptionName;
    this.setState({ translQuestion });
  }

  handleTranslateOptionHeadingInput = (translOptionHeadName, quesId, optId, translShortName) => {
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName]['headingList'][optId] = translOptionHeadName;
    this.setState({ translQuestion });
  }


  onTranslateQuestionSave = (isSave, translShortName) => {
    let { translQuestion, translSubQuestionsMatrix } = this.state;

    if (isSave) {
      if (translShortName) {
        let data = {
          "id": translQuestion.id,
          "transl": translQuestion.transl
        }


        let tempdate = {
          surveyId: this.props.match.params.id
        }


        let TranslationSubList = []
        if (translSubQuestionsMatrix) {
          let questionIdList = Object.keys(translSubQuestionsMatrix);
          if (questionIdList && questionIdList.length > 0) {
            questionIdList.forEach((QuesId) => {
              if (translSubQuestionsMatrix && translSubQuestionsMatrix[QuesId]) {
                TranslationSubList.push(translSubQuestionsMatrix[QuesId])
              }
            });
          }
        }

        this.props.dispatch(questionsActions.translateQuestion(data, tempdate, TranslationSubList));
      }



      let clearData = {
        "id": "",
        "transl": {}
      }
      this.setState({ translQuestion: clearData, currQuesEditId: '', translSubQuestionsMatrix });

    }
    else {
      let clearData = {
        "id": "",
        "transl": {}
      }
      this.setState({ translQuestion: clearData, currQuesEditId: '', translSubQuestionsMatrix: clearData });
    }



  }

  handleWelcomeTextTranslate = (value, translShortName) => {
    let { translSurvey } = this.state;
    translSurvey['id'] = this.props.match.params.id;

    let withJSdata = "";
    if (value) {
      withJSdata = value.replace(/<script.*>.*<\/script>/ims, " ");
    }

    translSurvey['transl'][translShortName]['welcomeText'] = withJSdata;
    this.setState({ translSurvey });
  }
  handleInstructionTextTranslate = (value, translShortName) => {
    let { translSurvey } = this.state;
    translSurvey['id'] = this.props.match.params.id;

    let withJSdata = "";
    if (value) {
      withJSdata = value.replace(/<script.*>.*<\/script>/ims, " ");
    }

    translSurvey['transl'][translShortName]['instructionText'] = withJSdata;
    this.setState({ translSurvey });
  }
  handleThankyouTextTranslate = (value, translShortName) => {
    let { translSurvey } = this.state;
    translSurvey['id'] = this.props.match.params.id;

    let withJSdata = "";
    if (value) {
      withJSdata = value.replace(/<script.*>.*<\/script>/ims, " ");
    }

    translSurvey['transl'][translShortName]['thankyouText'] = withJSdata;
    this.setState({ translSurvey });
  }


  handleTranslSurveyName = (e, translShortName) => {
    let { translSurvey } = this.state;
    translSurvey['transl'][translShortName]['name'] = e.target.value;
    this.setState({ translSurvey });

    //console.log('translSurvey; ', translSurvey)
  }

  handleTranslSurveyInput = (value, name, translShortName) => {
    let { translSurvey } = this.state;
    translSurvey['transl'][translShortName][name] = value;
    this.setState({ translSurvey });

    //console.log('translSurvey; ', translSurvey)
  }

  handleTranslSurveyDesc = (e, translShortName) => {
    let { translSurvey } = this.state;
    translSurvey['transl'][translShortName]['desc'] = e.target.value;;
    this.setState({ translSurvey });
  }




  addMatrixQFn = (question, comp) => {

    let { questions } = this.props;
    let { questionsList } = questions;

    if (comp === "row") {




      let SubQues = {
        questionTypeId: question.questionTypeId,
        templateId: question.templateId,

        parentId: question.id,

        isMandatory: false,

        surveyId: this.props.match.params.id,
        name: "Question",
        options: []
      }


      //console.log('SubQues>.........................', SubQues)

      let tempcall = {
        surveyId: this.props.match.params.id

      }

      this.props.dispatch(questionsActions.createQuestion(SubQues, tempcall));


    }

    if (comp === "col") {

      this.props.dispatch(questionsActions.addOption(questionsList, question));
    }



  }

  handleInputMatrixQues = (Value, Que, SubQues) => {

    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.handleSubQuestionName(questionsList, Que.id, SubQues.id, Value));


  }


  handleRankDash = (templateNo, value) => {
    let { dashQuesTypeRank } = this.state;
    dashQuesTypeRank[templateNo] = parseInt(value);
    this.setState({ dashQuesTypeRank })
  }


  handleLogicQuestionOrOption = (value, type) => {
    let { translQuestion } = this.state;
    if (type === "QuestionId") {
      translQuestion['transl']["questionLogic"]["id"] = value
      // translQuestion['transl']["questionLogic"]["answers"] = [];

      this.setState({ logicQuesId: value });
    }
    else if (type === "Options" && (translQuestion && translQuestion['transl'] && translQuestion['transl']["questionLogic"] && translQuestion['transl']["questionLogic"]["id"])) {
      // this.setState({logicOptions: value},()=>{
      // });

      let optionsSelected = translQuestion['transl']["questionLogic"]["answers"];

      let getIndex = optionsSelected.findIndex(prev => prev === value);
      if (getIndex === -1) {
        translQuestion['transl']["questionLogic"]["answers"].push(value);
      }
      else {
        translQuestion['transl']["questionLogic"]["answers"].splice(getIndex, 1);
      }



    }
    else if (type === "EnableLogic") {
      translQuestion['transl']["questionLogic"]["enable"] = value;
    }
    this.setState({ translQuestion });

  }


  handleNewParameterInput = (e) => {
    let { parameterFormData } = this.state;
    parameterFormData[e.target.name] = e.target.value
    this.setState({ parameterFormData });
  }

  handleNewParameterTypeInput = (data) => {
    let { parameterFormData } = this.state;
    parameterFormData["parameterTypeId"] = data.id;
    parameterFormData["parameterTypeLevel"] = data.level;
    this.setState({ parameterFormData });
  }

  handleNewParameterSubmit = () => {
    let { parameterFormData, isBucket, CurrBucketId } = this.state;
    let { dashboard } = this.props;
    let { getParameterTypeListData } = dashboard;


    let temp = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    if (isBucket) {

      let dataParaType = {
        "name": "",
        "label": ""
      };
      if (getParameterTypeListData && getParameterTypeListData.length > 0) {
        let getIndex = getParameterTypeListData.findIndex(prev => prev.level === 1);
        dataParaType = getParameterTypeListData[getIndex];
      }
      //////console.log("dataParaType--->", dataParaType)


      let data = {
        name: parameterFormData.name,
        label: parameterFormData.label,
        parameterTypeId: dataParaType.id,
        parameterTypeLevel: dataParaType.level
      }

      this.props.dispatch(dashboardActions.createParameter(data, temp, true));
    }
    else {

      let dataParaType = {
        "name": "",
        "label": ""
      };
      if (getParameterTypeListData && getParameterTypeListData.length > 0) {
        let getIndex = getParameterTypeListData.findIndex(prev => prev.level === 2);
        dataParaType = getParameterTypeListData[getIndex];
      }
      let data = {
        name: parameterFormData.name,
        label: parameterFormData.label,
        parameterTypeId: dataParaType.id,
        parameterTypeLevel: dataParaType.level,
        parentId: CurrBucketId
      }

      this.props.dispatch(dashboardActions.createParameter(data, { parentId: CurrBucketId }, false));
    }


    this.setState({ showCreateParameterModal: false })

  }

  handleClearParameterForm = () => {
    let clearData = {
      "name": "",
      "label": "",
      "parameterTypeId": "",
      "parameterTypeLevel": ""
    }

    this.setState({ parameterFormData: clearData });
  }


  handleCollectParaLib = (data) => {
    let { CrrParaLibCollection } = this.state;

    let getIndex = CrrParaLibCollection.findIndex(prev => prev === data.id);
    if (getIndex === -1) {
      CrrParaLibCollection.push(data.id);
    }
    else {
      CrrParaLibCollection.splice(getIndex, 1);
    }

    this.setState({ CrrParaLibCollection });

  }


  handleSubmitCopyParaLibrary = () => {
    let { CrrParaLibCollection } = this.state;
    if (CrrParaLibCollection && CrrParaLibCollection.length > 0) {
      let data = {
        "paraIdList": CrrParaLibCollection
      }
      let temp = {
        "keyWord": "",
        "pageNo": 1,
        "size": 500
      }
      this.props.dispatch(surveyActions.copyParaLibToParameter(data, temp));
    }
    this.setState({ showCreateParameterModal: false })
  }


  // handleQuestionDesign=(ques,type)=>{
  //   let { crrQuestionDesignObj } = this.state;
  //   let templateId = ques && ques.templateId?ques.templateId:"";
  //   let questionId = ques && ques.id?ques.id:"";
  //   crrQuestionDesignObj[questionId] = type;
  //   this.setState({crrQuestionDesignObj})
  //   //////console.log("templateId---type ->",templateId," - ",type);
  // }


  onQuesRandomize = () => {
    let tempData = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.onSurveyQueRandomize(tempData));

  }

  onSettings = (check) => {
    this.setState({ showSettings: check, allqueSeconds: 0 })
  }

  handleAllQuesSecInput = (value) => {
    if (value < 300) {
      this.setState({ allqueSeconds: value })
    }
  }

  onSaveSettings = () => {
    let { allqueSeconds } = this.state;
    let tempData = {
      surveyId: this.props.match.params.id,
      seconds: allqueSeconds ? allqueSeconds : 0
    }
    this.props.dispatch(questionsActions.onSurveyAllQueSeconds(tempData));
    this.setState({ showSettings: false })
  }

  onQuesAllMandatory = () => {
    let tempData = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(questionsActions.updateQuesAllMandatory(tempData));
  }

  handleNudgeModal = (ques, check) => {
    console.log("==>ques,check---------->: ", ques, check);
    this.props.dispatch(dashboardActions.getNudgeLibList());

    this.setState({ openAddNudgeModal: ques });

  }

  handleDMGModal = (ques, check) => {
    console.log("==>ques,check---------->: ", ques, check);
    if (check) {
      this.props.dispatch(surveyActions.getSurveyDMGLaunchFilter());
      this.props.dispatch(surveyActions.getSurveyLaunchByDMGValue());
      this.setState({ openAddDMGModal: ques });
    } else {
      this.setState({ openAddDMGModal: false });
    }

  }


  addNudgeToQues = (question, Nudge) => {

    if (question && question.id && Nudge && Nudge.id) {
      let data = {
        questionId: question.id,
        nudgelibId: Nudge.id
      }

      let temp = {
        surveyId: this.props.match.params.id
      }
      this.props.dispatch(questionsActions.addNudgeToQuestion(data, temp));
    }

    this.setState({ openAddNudgeModal: false });

  }

  SelectOfQuesLibList = (ques_list) => {
    let { QuestionLibraryFinalList } = this.state;


    if (ques_list && ques_list.length > 0) {
      ques_list.forEach((question) => {

        let getIndex = QuestionLibraryFinalList.findIndex(prev => prev === question.id);
        if (getIndex === -1) {
          QuestionLibraryFinalList.push(question.id)
        } else {
          QuestionLibraryFinalList.splice(getIndex, 1);
        }

      })
    }

    this.setState({ QuestionLibraryFinalList });

  }


  openModifyIndexModal = (value) => {
    this.setState({ showModifyIndexModal: value });
  }




  onDeleteQuestionList = (list) => {
    this.setState({ deleteQuestionList: list, showModifyIndexModal: false }, () => {
      let reqData = {
        "surveyId": this.props.match.params.id,
        "questionList": list
      }
      this.props.dispatch(questionsActions.deleteManyQuestions(reqData));
    });
  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    this.setState({ FilterValues: finalFilter, currFilterName: name });
  }

  submitDemographicModal = (Ques) => {
    let { FilterValues, currFilterName, openAddDMGModal } = this.state;

    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        Filters = FilterValues
      }
    }

    let reqData = {
      id: Ques.id,
      filters: Filters,
      dmgModule: openAddDMGModal && openAddDMGModal["dmgModule"] ? openAddDMGModal["dmgModule"] : 'NONE'
    }

    let temp = {
      surveyId: this.props.match.params.id
    }

    this.props.dispatch(questionsActions.updateQuestionDMG(reqData, temp));
    this.setState({ openAddDMGModal: false });

    setTimeout(() => {
      this.setState({ FilterValues: [], currFilterName: "" });
    }, 500)

  }

  handleDMGType = (dmgType) => {
    let { openAddDMGModal } = this.state;
    openAddDMGModal["dmgModule"] = dmgType;
    this.setState({ openAddDMGModal });
  }

  render() {
    //////console.log("==>crrQuestionDesignObj---------->: ", this.state.crrQuestionDesignObj);

    //ROLE MANAGEMENT ----------------------------------------------

    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    //console.log('moduleAccess: ', moduleAccess)

    //let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        //SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        //SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------



    let { survey, questions, dashboard } = this.props;
    let { getNudgeLibListData } = dashboard;
    let { getSurveyLaunchByDMGValueData, getSurveyDMGLaunchFilterData, getParameterLibraryListbyParameterTypeData, getQuestionLibraryListData, getLanguageLabelData, getLanguageData, getQuestionTypeListData, getSurveyData, parameterList, getParameterListbyParentData } = survey;
    let { questionsList } = questions;
    let { deleteQuestionList, showModifyIndexModal, openAddNudgeModal, openAddDMGModal, crrQuestionDesignObj, dashQuesTypeRank, showTranslAttributes, ShowTranslWelcome, translSurvey, CurrTranslShortName, showQuestionLibraryModal, showDesignPage, showPleaseSaveQuesModal, showLanguageModal, CurrentLanguageName, CurrentLanguage, CrrRoute, showSidebar, showTranslatePage } = this.state;

    let loading = survey.loading || questions.loading;


    let { allqueSeconds, showSettings, isBucket, showDashboardDesignPage, showLogicPage, showAddQuestion, showWelcomePage, showPreviewPage, welcomeFormData, currQuesEditId, imageWebLinkLogo, imageWebLinkLogo2, imageWebLink, imageWebLink2,
      showBucketDropdown, showDimensionDropdown, paraBucketName, paraDimensionName, showQuestionTypeList, questionTypeTemplate, showEditSurveyName, surveyUpdateFormData } = this.state;

    let { getAllParameterListData, getParameterTypeListData, getCompanyNameOnlyData } = this.props.dashboard;




    let checkAllMandatory = false;
    if (questionsList && questionsList.length > 0) {
      let quesMandatoryList = questionsList.filter(prev => prev.isMandatory === true);
      if (questionsList && questionsList.length > 0) {
        if (quesMandatoryList.length === questionsList.length) {
          checkAllMandatory = true;
        }
      }
    }

    // let dashType1Check = getSurveyData && getSurveyData.templateId<3?true:false;
    let dashType1Check = true;



    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }



    let parameterListOptions = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        if (item.parameterTypeLevel === 1) {
          parameterListOptions.push(
            {
              value: GetParameterLabel(item.name),
              label: GetParameterLabel(item.name),
              id: item.id,
            }
          )
        }
      })
    }

    let parameterDefault = []
    if (getSurveyData && getSurveyData.parameterList && getSurveyData.parameterList.length > 0) {

      getSurveyData.parameterList.forEach((item) => {
        parameterDefault.push(
          {
            value: GetParameterLabel(item.name),
            label: GetParameterLabel(item.name),
            id: item.id,
          }
        )
      })
    }






    let orgListOptions = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        if (item && item.parameterTypeLevel === 2 && item.parentId && item.parentId.name === "OrganizationCore") {
          orgListOptions.push(
            {
              value: GetParameterLabel(item.name),
              label: GetParameterLabel(item.name),
              id: item.id,
            }
          )
        }
      })
    }

    let orgDefault = []
    if (getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {

      getSurveyData.orgList.forEach((item) => {
        orgDefault.push(
          {
            value: GetParameterLabel(item.name),
            label: GetParameterLabel(item.name),
            id: item.id,
          }
        )
      })
    }







    console.log("getSurveyData-----getSurveyData--------------->", !(getSurveyData && getSurveyData.isNonEdit))



    //---------------------------------------------------------------------------------------




    let langListOptions = []
    if (getLanguageData && getLanguageData.length > 0) {
      getLanguageData.forEach((item) => {
        langListOptions.push(
          {
            value: item.name,
            label: item.name,
            id: item.id,
          }
        )
      });
    }

    let LangDefault = []
    if (getSurveyData && getSurveyData.langList && getSurveyData.langList.length > 0) {

      getSurveyData.langList.forEach((item) => {
        LangDefault.push(
          {
            value: item.name,
            label: item.name,
            id: item.id,
          }
        )
      });
    }




    //---------------------------------------------------------------------------------------








    if (questionsList && questionsList.length > 0) {
      questionsList.sort((a, b) => a.rank - b.rank);
      // //console.log('questionsList V : ', questionsList)
    }


    let SurveyName = '';

    let SurveyisExpire = '';
    let SurveyexpireTime = '';

    if (getSurveyData) {
      SurveyName = getSurveyData.name;
      SurveyisExpire = getSurveyData.isExpire ? true : (moment(moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? true : false);
      SurveyexpireTime = (getSurveyData && getSurveyData.expireTime) ? moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    }


    let SurveyId = this.props.match.params.id;


    // //console.log('getSurveyData V : ', getSurveyData)

    let SurveyTemplateId = 0;
    if (getSurveyData && getSurveyData.templateId) {
      SurveyTemplateId = getSurveyData.templateId;
    }


    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    // //console.log('LanguageObj V : ', LanguageObj)

    let QuestionTypeListData = [];
    if (getQuestionTypeListData && getQuestionTypeListData.length > 0) {
      for (let i = getQuestionTypeListData.length - 1; i >= 0; i--) {
        if (getSurveyData && getSurveyData.isPackage) {
          if (getQuestionTypeListData[i]["templateId"] === 1) {
            QuestionTypeListData.push(getQuestionTypeListData[i]);
          }
        } else {
          QuestionTypeListData.push(getQuestionTypeListData[i]);
        }
      }
    }






    // //console.log('translSurvey =========> : ', translSurvey)
    // //console.log('translQuestion =========> : ', translQuestion)

    let LanguageTranslValueList = []
    if (welcomeFormData && welcomeFormData.langListUpdate && welcomeFormData.langListUpdate.length > 0) {
      welcomeFormData.langListUpdate.forEach((langId) => {
        if (getLanguageData && getLanguageData.length > 0) {
          getLanguageData.forEach((item) => {
            if (item.id === langId) {
              LanguageTranslValueList.push(item)
            }
          });
        }

      });
    }


    function LabelTransl(short) {
      if (getLanguageData && getLanguageData.length > 0) {
        let getIndex = getLanguageData.findIndex(prev => prev.shortName === short);


        if (getIndex !== -1) {
          return getLanguageData[getIndex].name;
        }
        else {
          return ""
        }
      }
      else {
        return ""
      }

    }
    // //console.log('getLanguageData =========> : ', getLanguageData)
    // //console.log('translShortName =========> : ', translShortName)

    // //console.log('questionsList =========> : ', questionsList)



    let translShortName = '';
    if (LanguageTranslValueList && LanguageTranslValueList.length > 0) {
      if (CurrTranslShortName) {
        translShortName = CurrTranslShortName
      }
      else {
        translShortName = LanguageTranslValueList[0].shortName;
      }
    }

    //console.log('questionsList =========> : ', questionsList)
    let surveyModuleNo = null;
    if (getSurveyData && getSurveyData.surveyModule) {
      surveyModuleNo = getSurveyData.surveyModule
    }

    // console.log('questionsList V : ', questionsList);


    function getFormattedParameter(element, field1, field2) {
      return element && element[field1] && element[field1][field2] ? element[field1][field2] : ""
    }

    let crrRangeList = []
    if (openAddNudgeModal && openAddNudgeModal.id) {
      if (openAddNudgeModal && openAddNudgeModal.nudgetextId && openAddNudgeModal.nudgetextId.rangeList && openAddNudgeModal.nudgetextId.rangeList.length > 0) {
        crrRangeList = openAddNudgeModal.nudgetextId.rangeList;
      }
    }



    function getEMPFilterLabel(name) {

      let label = name;
      if (getSurveyDMGLaunchFilterData && getSurveyDMGLaunchFilterData.length > 0) {
        let getIndex = getSurveyDMGLaunchFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = getSurveyDMGLaunchFilterData && getSurveyDMGLaunchFilterData[getIndex] && getSurveyDMGLaunchFilterData[getIndex].label ? getSurveyDMGLaunchFilterData[getIndex].label : name
        }
      }
      return label

    }



    let dmgModuleList = ['NONE', 'SELF', 'CROSS'];





    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading...'
          className="loader"
        >



          {/* <div>
            <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
              LanguageLabel={this.LanguageLabel}

              LanguageObj={LanguageObj}
              CurrentLanguage={CurrentLanguage}

            />


            <main className="h-screen lg:ml-16 "> */}



          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={this.props.dashboard.ClientProfileData}
              handleLogout={this.handleLogout}
              props={this.props}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
              sidebarToggle={this.sidebarToggle}
            />

            <div className="flex w-full" style={{ height: 'calc(100% - 2rem)' }}>

              <Sidebar
                onOverview={this.onOverview}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                accessAllow={accessAllow}
                routeLink={this.routeLink}
                showMobileSidebar={this.state.showMobileSidebar}
                sidebarToggle={this.sidebarToggle}
              />
              <main className="flex-1 w-full overflow-hidden overflow-y-auto ">


                {/* header end */}
                {accessAllow("createSurvey") ?
                  <>
                    <div className="h-full overflow-hidden overflow-y-auto bg-[#f9fafb] h-[calc(100%-2rem)] " >
                      {/*sub nav bar 1 */}
                      <div className="items-center justify-between px-4 py-4 space-y-4 bg-white border-b md:flex md:space-y-0">
                        {!showEditSurveyName ?
                          <div className="flex items-center space-x-4 ">
                            <span onClick={() => this.props.history.goBack()} className="p-2 text-blue-500 duration-150 bg-blue-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-100 trasition" style={{ fontSize: '1.2rem', }} >arrow_back</span>


                            <h2 className="text-base font-medium truncate cursor-default">{SurveyName}</h2>
                            <i onClick={() => this.handleEditSurveyName(getSurveyData)} className="material-symbols-outlined text-blue-500 cursor-pointer  bg-blue-100 rounded-md p-1.5"
                              style={{ fontSize: '1.2rem' }}>edit</i>
                          </div>
                          :
                          <div className="">
                            <div className="float-left"><input type="text" name="name" value={surveyUpdateFormData.name} onChange={this.handleUpdateSurveyNameInput} placeholder="Survey title here..." className="w-full px-2 py-1 border border-gray-300 rounded-md outline-none" /></div>
                            <div className="float-left px-3 py-1 cursor-pointer material-symbols-outlined" onClick={() => this.updateSurvey()}>done</div>
                            <div className="float-left px-3 py-1 cursor-pointer material-symbols-outlined" onClick={() => { this.setState({ showEditSurveyName: false }) }}>close</div>
                          </div>
                        }

                        <div className="">
                          <div className="flex items-center justify-center overflow-hidden border divide-x rounded-md ">
                            <div>
                              {showAddQuestion ?
                                <button title='Save' onClick={() => this.saveAutoParameter(false)} className="px-4 py-2  text-sm text-[#212121] hover:bg-[#2196f3] hover:text-white flex space-x-2 items-center">
                                  <TbFile />  <span>Save</span>
                                </button>
                                :

                                <button title='Save' onClick={() => this.saveWelcomePage()} className="px-4 py-2  text-sm text-[#212121] hover:bg-[#2196f3] hover:text-white flex space-x-2 items-center">{"Save"}</button>

                              }
                            </div>
                            <button title='Preview' onClick={() => this.onPreviewFullPage()} className="px-4 py-2  text-sm text-[#212121] hover:bg-[#2196f3] hover:text-white flex space-x-2 items-center">
                              <TbEye /> <span>Preview </span>
                            </button>
                            {questionsList && questionsList.length > 0 ?
                              <button title='Preview' onClick={() => this.onPublishSurvey()} className="px-4 py-2  text-sm text-[#212121] hover:bg-[#2196f3] hover:text-white flex space-x-2 items-center">
                                {LanguageObj && LanguageObj["publish_label_" + CurrentLanguage] ? LanguageObj["publish_label_" + CurrentLanguage] : ""}
                                <TbSend />
                                <span>Publish</span>

                              </button>
                              : null}
                          </div>
                        </div>
                        <div className="flex items-center ">
                          <div>
                            {/* <p className="mr-2 text-sm cursor-default">{LanguageObj && LanguageObj["mark_as_Expired_label_"+CurrentLanguage]? LanguageObj["mark_as_Expired_label_"+CurrentLanguage]:"Mark as Expired"}</p> */}
                          </div>
                          <input
                            id="expireTime"
                            type="date"
                            className=
                            {SurveyisExpire ?
                              "cursor-pointer px-2 border border-red-500 rounded-md text-sm p-1.5" :
                              "cursor-pointer px-2 border border rounded-md text-sm p-1.5 focus:ring-1 focus:outline-none"
                            }
                            name="expireTime"
                            value={SurveyexpireTime}
                            onChange={(e) => this.handleExpiryTime(e, SurveyisExpire, SurveyexpireTime)}
                          />
                          <div className="flex items-center ml-4 space-x-2">
                            <input onClick={() => this.isExpired(SurveyisExpire, SurveyexpireTime)} checked={SurveyisExpire} type="checkbox" id="remember" className="w-4 h-4 transition duration-300 rounded cursor-pointer focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200" />
                            {/* <label htmlFor="remember" className="text-xs text-gray-500">{LanguageObj && LanguageObj["my_surveys_label_"+CurrentLanguage]? LanguageObj["my_surveys_label_"+CurrentLanguage]:"My Surveys"}</label> */}
                            <label htmlFor="remember" className={SurveyisExpire ? "text-xs text-red-500" : "text-xs text-gray-500"}>{LanguageObj && LanguageObj["mark_as_Expired_label_" + CurrentLanguage] ? LanguageObj["mark_as_Expired_label_" + CurrentLanguage] : "Mark As Expired"}</label>

                          </div>
                        </div>
                      </div>
                      {/* end */}
                      {/*sub nav bar 2 */}
                      <div className="flex items-center justify-center w-full pt-4 lg:px-4 ">
                        <div className="flex justify-center space-x-2 ">

                          {/* <span className="py-4 mr-8 text-xs font-bold tracking-wide uppercase border-b-2 border-transparent o-underline text-grey-dark hover:border-blue-500" href=" ">INSTRUCTIONS</span> */}

                          <button onClick={() => this.onQuestionPage()} className={showAddQuestion ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]  " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border "}>
                            {LanguageObj && LanguageObj["question_label_" + CurrentLanguage] ? LanguageObj["question_label_" + CurrentLanguage] : "Questions"}
                          </button>

                          <span onClick={() => this.onWelcomePage("welcome")} className={showWelcomePage ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]   " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border"}  >
                            {LanguageObj && LanguageObj["design_label_" + CurrentLanguage] ? LanguageObj["design_label_" + CurrentLanguage] : "Content"}
                          </span>

                          <span onClick={() => this.onWelcomePage("design")} className={showDesignPage ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]    " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border "}  >
                            {LanguageObj && LanguageObj["design_label_" + CurrentLanguage] ? LanguageObj["design_label_" + CurrentLanguage] : "Design"}
                          </span>

                          <span onClick={() => this.onWelcomePage("translate", translShortName)} className={showTranslatePage ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]   " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border "} href>
                            {LanguageObj && LanguageObj["translate_label_" + CurrentLanguage] ? LanguageObj["translate_label_" + CurrentLanguage] : "Translation"}
                          </span>


                          {/* {!(getSurveyData && getSurveyData.isNonEdit) || true ?
                              <span onClick={() => this.onWelcomePage("translate", translShortName)} className={showTranslatePage ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]   " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border "} href>
                                {LanguageObj && LanguageObj["translate_label_" + CurrentLanguage] ? LanguageObj["translate_label_" + CurrentLanguage] : "Translation"}
                              </span>
                              :
                              <span className={showTranslatePage ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4]  to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]   " : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70   rounded-t-md border "} href>
                                {LanguageObj && LanguageObj["translate_label_" + CurrentLanguage] ? LanguageObj["translate_label_" + CurrentLanguage] : "Translation"}
                              </span>
                            } */}


                          {/*
                            {surveyModuleNo === 6 ?
                              <span onClick={() => this.onSettings(true)} className={showSettings ? "cursor-pointer text-grey-dark border-b-2 hover:bg-gray-100 border-blue-500 capitalize tracking-wide font-semibold text-sm py-3 " : "cursor-pointer  text-grey-dark border-b-2 hover:bg-gray-100 border-transparent capitalize tracking-wide font-medium text-xs py-4 px-2 rounded-t-lg "} href>
                                {LanguageObj && LanguageObj["settings_label_" + CurrentLanguage] ? LanguageObj["settings_label_" + CurrentLanguage] : "Settings"}
                              </span>
                              : null}
                            */}




                        </div>
                      </div>
                      <div className="">

                        {showWelcomePage ?
                          <>
                            <WelcomePage
                              welcomeFormData={welcomeFormData}
                              parameterListOptions={parameterListOptions}
                              parameterDefault={parameterDefault}
                              orgListOptions={orgListOptions}
                              orgDefault={orgDefault}

                              handleWelcomeTextInput={this.handleWelcomeTextInput}
                              handleInstructionTextInput={this.handleInstructionTextInput}
                              handleDisclaimerTextInput={this.handleDisclaimerTextInput}
                              handleThankyouTextInput={this.handleThankyouTextInput}

                              handleSurveyParameterList={this.handleSurveyParameterList}
                              handleSurveyorgList={this.handleSurveyorgList}

                              handleChangetextColor={this.handleChangetextColor}
                              handleChangebgColor={this.handleChangebgColor}

                              handleFile={this.handleFile}
                              handleUpload={this.handleUpload}
                              handleImageRemove={this.handleImageRemove}
                              imageWebLinkLogo={imageWebLinkLogo}

                              handleFileLogo={this.handleFileLogo}
                              handleUploadLogo={this.handleUploadLogo}
                              handleLogoRemove={this.handleLogoRemove}
                              imageWebLink={imageWebLink}

                              handlebgType={this.handlebgType}

                              ColorCodeList={this.state.ColorCodeList}

                              handleColorCode={this.handleColorCode}

                              selectColorType={this.state.selectColorType}


                              handleChangeColorValue={this.handleChangeColorValue}

                              handleInstructionCheck={this.handleInstructionCheck}
                              handleDisclaimerCheck={this.handleDisclaimerCheck}

                              instructionCheck={this.state.instructionCheck}

                              SurveyTemplateId={SurveyTemplateId}
                            // saveWelcomePage={this.saveWelcomePage}
                            />
                          </>
                          : null}


                        {showDesignPage ?
                          <>
                            <DesignPage
                              welcomeFormData={welcomeFormData}
                              parameterListOptions={parameterListOptions}
                              parameterDefault={parameterDefault}
                              orgListOptions={orgListOptions}
                              orgDefault={orgDefault}

                              handleWelcomeTextInput={this.handleWelcomeTextInput}
                              handleInstructionTextInput={this.handleInstructionTextInput}
                              handleThankyouTextInput={this.handleThankyouTextInput}

                              handleSurveyParameterList={this.handleSurveyParameterList}
                              handleSurveyorgList={this.handleSurveyorgList}

                              handleChangetextColor={this.handleChangetextColor}
                              handleChangebgColor={this.handleChangebgColor}

                              handleFile={this.handleFile}
                              handleUpload={this.handleUpload}
                              handleImageRemove={this.handleImageRemove}
                              imageWebLinkLogo={imageWebLinkLogo}
                              imageWebLinkLogo2={imageWebLinkLogo2}

                              handleFileLogo={this.handleFileLogo}
                              handleFileLogo2={this.handleFileLogo2}
                              handleUploadLogo={this.handleUploadLogo}
                              handleLogoRemove={this.handleLogoRemove}
                              imageWebLink={imageWebLink}
                              imageWebLink2={imageWebLink2}

                              handlebgType={this.handlebgType}

                              ColorCodeList={this.state.ColorCodeList}

                              handleColorCode={this.handleColorCode}

                              selectColorType={this.state.selectColorType}


                              handleChangeColorValue={this.handleChangeColorValue}

                              handleInstructionCheck={this.handleInstructionCheck}

                              instructionCheck={this.state.instructionCheck}

                              SurveyTemplateId={SurveyTemplateId}
                              // saveWelcomePage={this.saveWelcomePage}

                              handleSurveyTemplate={this.handleSurveyTemplate}


                              logoUploadError={this.state.logoUploadError}
                              imageUploadError={this.state.imageUploadError}
                            />
                          </>
                          : null}




                        {showDashboardDesignPage ?
                          <>
                            <DashboardDesign
                              welcomeFormData={welcomeFormData}
                              dashQuesTypeRank={dashQuesTypeRank}
                              getQuestionTypeListData={getQuestionTypeListData}
                              handleRankDash={this.handleRankDash}
                            />
                          </>
                          : null}




                        {showLogicPage ?
                          <>
                            <LogicPage
                              welcomeFormData={welcomeFormData}
                              parameterListOptions={parameterListOptions}
                              parameterDefault={parameterDefault}
                              orgListOptions={orgListOptions}
                              orgDefault={orgDefault}

                              handleWelcomeTextInput={this.handleWelcomeTextInput}
                              handleInstructionTextInput={this.handleInstructionTextInput}
                              handleThankyouTextInput={this.handleThankyouTextInput}

                              handleSurveyParameterList={this.handleSurveyParameterList}
                              handleSurveyorgList={this.handleSurveyorgList}

                              handleChangetextColor={this.handleChangetextColor}
                              handleChangebgColor={this.handleChangebgColor}

                              handleFile={this.handleFile}
                              handleUpload={this.handleUpload}
                              handleImageRemove={this.handleImageRemove}
                              imageWebLinkLogo={imageWebLinkLogo}

                              handleFileLogo={this.handleFileLogo}
                              handleUploadLogo={this.handleUploadLogo}
                              handleLogoRemove={this.handleLogoRemove}
                              imageWebLink={imageWebLink}

                              handlebgType={this.handlebgType}

                              ColorCodeList={this.state.ColorCodeList}

                              handleColorCode={this.handleColorCode}

                              selectColorType={this.state.selectColorType}


                              handleChangeColorValue={this.handleChangeColorValue}

                              handleInstructionCheck={this.handleInstructionCheck}

                              instructionCheck={this.state.instructionCheck}

                              SurveyTemplateId={SurveyTemplateId}
                              // saveWelcomePage={this.saveWelcomePage}

                              GetParameterLabel={GetParameterLabel}

                              LangDefault={LangDefault}
                              handleSurveyLangList={this.handleSurveyLangList}
                              langListOptions={langListOptions}


                            />
                          </>
                          : null}


                        {showAddQuestion ?

                          <main>
                            <div className="w-full px-4 py-6 mx-auto xl:w-4/6 lg:w-5/6">

                              {/*
                              {surveyModuleNo === 6 && showSettings ?
                                <div className="relative p-4 px-6 mt-3 mb-2 bg-white border">
                                  <div className="px-4 ">
                                    <div className='font-bold text-gray-500 text-md'>Assessment Settings</div>

                                    <div className='flex'>

                                      <div className="py-1 mt-2" >
                                        <label htmlFor="mandatory-check" className="px-2 py-2 text-xs font-medium tracking-wide capitalize border-b-2 border-transparent rounded-t-lg text-grey-dark">{"Question Time (in sec)"}</label>

                                        <input
                                          value={allqueSeconds}
                                          type="number"
                                          onChange={(event) => this.handleAllQuesSecInput(parseInt(event.target.value))}
                                          className="w-24 px-2 ml-1 border border-gray-500 cursor-pointer"
                                        />
                                      </div>

                                      <div className='flex justify-center mx-6 mt-2'>
                                        <label htmlFor="mandatory-check" className="px-2 py-2 text-xs font-medium tracking-wide capitalize border-b-2 border-transparent rounded-t-lg text-grey-dark">{"Randomize Questions"}</label>

                                        <div className='mt-1 cursor-pointer' onClick={() => this.onQuesRandomize()}>
                                          {getSurveyData && getSurveyData.isRandomize ?
                                            <div className="flex justify-center ">
                                              <div className="rounded-full w-11 h-5 cursor-pointer   p-0.5 bg-green-400">
                                                <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform translate-x-3 bg-white rounded-full" />
                                              </div>
                                            </div>
                                            :
                                            <div className="flex justify-center ">

                                              <div className="rounded-full w-11 h-5 p-0.5 bg-gray-200">
                                                <div className="w-4 h-4 mx-auto duration-300 ease-in-out transform -translate-x-3 bg-white rounded-full" />
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      </div>

                                    </div>

                                    <div className="flex justify-center pt-8 pb-2">
                                      <span onClick={() => this.onSaveSettings()} className="cursor-pointer text-sm rounded uppercase   bg-[#2196f3] py-2 px-4 text-white w-28  mx-1 text-center"
                                      > save</span>
                                    </div>

                                  </div>
                                </div>
                                : null}
                                */}

                              <div className='relative items-center p-4 space-x-4 bg-white border'>
                                <label className='font-medium'>Survey Settings</label>

                                <label htmlFor="mandatory-check"
                                  className="flex-row gap-4 text-sm text-gray-600">
                                  <input
                                    id="mandatory-check"
                                    value={checkAllMandatory}
                                    checked={checkAllMandatory}
                                    type="checkbox"
                                    onChange={() => { }}
                                    className="mr-1 cursor-pointer"
                                    onClick={() => this.onQuesAllMandatory()}
                                  />

                                  <span> Mark All Questions Mandatory</span></label>

                                <label onClick={() => this.openModifyIndexModal(true)} className='px-4 py-2 font-medium text-gray-500 border border-gray-500 rounded-lg hover:border-blue-500 hover:text-blue-500'>Modify Index</label>

                              </div>

                              {questionsList && questionsList.length > 0 ?
                                questionsList.map((question, quesindex) =>
                                  <div onClick={() => this.setState({ showQuestionTypeList: false })}
                                    className={question.parentId || question.templateId === 14 ?
                                      "border border-purple-300  py-6 px-1  bg-white  relative"
                                      : "border p-4 px-6 bg-white mb-2 mt-3 relative"
                                    }>
                                    <>
                                      <div className={question.parentId || question.templateId === 14 ? " px-6" : ""}>

                                        {/* Single Choice */}
                                        {question.templateId === 1 ?
                                          <>
                                            <SingleChoice
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}

                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              questionsList={questionsList}
                                              handleLogicQuestionOrOption={this.handleLogicQuestionOrOption}
                                              onCreateNewBucket={this.onCreateNewBucket}

                                              dashType1Check={dashType1Check}
                                              surveyModuleNo={surveyModuleNo}
                                              surveyDetails={getSurveyData}

                                              isPackage={getSurveyData && getSurveyData.isPackage}
                                              handleQuesSecInput={this.handleQuesSecInput}
                                              handleDMGModal={this.handleDMGModal}
                                            />
                                          </>
                                          : null}

                                        {/* DMG Single Choice */}
                                        {question.templateId === 21 ?
                                          <>
                                            <DMGSingleChoice
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}

                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              questionsList={questionsList}
                                              handleLogicQuestionOrOption={this.handleLogicQuestionOrOption}
                                              onCreateNewBucket={this.onCreateNewBucket}

                                              dashType1Check={dashType1Check}
                                              surveyModuleNo={surveyModuleNo}
                                              surveyDetails={getSurveyData}

                                              isPackage={getSurveyData && getSurveyData.isPackage}
                                              handleQuesSecInput={this.handleQuesSecInput}
                                              handleDMGModal={this.handleDMGModal}
                                            />
                                          </>
                                          : null}


                                        {/* Multiple Choice */}
                                        {question.templateId === 2 ?
                                          <>
                                            <MultipleChoice
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {/* TextBox */}
                                        {question.templateId === 3 ?
                                          <>
                                            <TextBox
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              onCreateNewBucket={this.onCreateNewBucket}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {/* TextBox */}
                                        {question.templateId === 23 ?
                                          <>
                                            <DMGTextBox
                                              handleDMGModal={this.handleDMGModal}
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              onCreateNewBucket={this.onCreateNewBucket}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}


                                        {/* Multiline Box */}
                                        {question.templateId === 4 ?
                                          <>
                                            <MultilineText
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              onCreateNewBucket={this.onCreateNewBucket}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}


                                        {/* Rating Scale */}
                                        {question.templateId === 5 ?
                                          <>
                                            <RatingScale
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}

                                              dashType1Check={dashType1Check}

                                              crrQuestionDesignObj={crrQuestionDesignObj}
                                              surveyDetails={getSurveyData}

                                              handleQuestionDesign={this.handleQuestionDesign}

                                            />
                                          </>
                                          : null}


                                        {/* NPS */}
                                        {question.templateId === 6 ?
                                          <>
                                            <NPS
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              handleNPSLable={this.handleNPSLable}
                                              surveyDetails={getSurveyData}
                                              surveyModuleNo={surveyModuleNo}

                                              dashType1Check={dashType1Check}

                                              isPackage={getSurveyData && getSurveyData.isPackage}
                                              onCreateNewBucket={this.onCreateNewBucket}

                                            />
                                          </>
                                          : null}


                                        {/*dmg NPS */}
                                        {question.templateId === 22 ?
                                          <>
                                            <DMGNPS
                                                    handleDMGModal={this.handleDMGModal}
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              handleNPSLable={this.handleNPSLable}
                                              surveyDetails={getSurveyData}
                                              surveyModuleNo={surveyModuleNo}

                                              dashType1Check={dashType1Check}

                                              isPackage={getSurveyData && getSurveyData.isPackage}
                                              onCreateNewBucket={this.onCreateNewBucket}

                                            />
                                          </>
                                          : null}


                                        {/* NPS */}
                                        {question.templateId === 16 ?
                                          <>
                                            <NPSV2
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              handleNPSLable={this.handleNPSLable}
                                              surveyDetails={getSurveyData}
                                              surveyModuleNo={surveyModuleNo}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {question.templateId === 7 ?
                                          <>
                                            <Email
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {question.templateId === 8 ?
                                          <>
                                            <Number
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}


                                        {question.templateId === 9 ?
                                          <>
                                            <Dropdown
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {question.templateId === 10 ?
                                          <>
                                            <Statement
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}


                                            />
                                          </>
                                          : null}


                                        {question.templateId === 11 ?
                                          <>
                                            <YesNo
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {question.templateId === 12 ?
                                          <>
                                            <Date
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {question.templateId === 13 ?
                                          <>
                                            <Matrix
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}

                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              onDeleteConfirm={this.onDeleteConfirm}
                                              handleInputMatrixQues={this.handleInputMatrixQues}
                                              handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
                                              addMatrixQFn={this.addMatrixQFn}

                                              handleAddOptionHeading={this.handleAddOptionHeading}
                                              showOptionHeading={this.state.showOptionHeading}
                                              handleOptionHeadingInput={this.handleOptionHeadingInput}
                                              handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}

                                        {question.templateId === 14 ?
                                          <>
                                            <GroupQuestions
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}

                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              onDeleteConfirm={this.onDeleteConfirm}
                                              handleInputMatrixQues={this.handleInputMatrixQues}
                                              handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
                                              addMatrixQFn={this.addMatrixQFn}

                                              handleAddOptionHeading={this.handleAddOptionHeading}
                                              showOptionHeading={this.state.showOptionHeading}
                                              handleOptionHeadingInput={this.handleOptionHeadingInput}
                                              handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}

                                              questionTypeTemplate={questionTypeTemplate}
                                              CurrentLanguage={CurrentLanguage}
                                              handleQuestionTypeGroup={this.handleQuestionTypeGroup}
                                              handleAddQuestion={this.handleAddQuestion}
                                              LanguageObj={LanguageObj}
                                              QuestionTypeListData={QuestionTypeListData}
                                              questionTypeTemplateGroup={this.state.questionTypeTemplateGroup}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />






                                          </>
                                          : null}

                                        {question.templateId === 15 ?
                                          <>
                                            <LocationQuestions
                                              question={question} handleNudgeModal={this.handleNudgeModal} quesindex={quesindex}
                                              currQuesEditId={currQuesEditId}
                                              handleQuestionInput={this.handleQuestionInput}
                                              handleOptionInput={this.handleOptionInput}
                                              handleWeightageInput={this.handleWeightageInput}
                                              onDeleteOption={this.onDeleteOption}
                                              onAddNewOption={this.onAddNewOption}
                                              onQuesMandatory={this.onQuesMandatory}
                                              onSaveQues={this.onSaveQues}
                                              handleParameterBucket={this.handleParameterBucket}
                                              showBucketDropdown={showBucketDropdown}
                                              showDimensionDropdown={showDimensionDropdown}
                                              parameterList={parameterList}
                                              paraBucketName={paraBucketName}
                                              handleParameterQuestion={this.handleParameterQuestion}
                                              handleParameterDimension={this.handleParameterDimension}
                                              getParameterListbyParentData={getParameterListbyParentData}
                                              paraDimensionName={paraDimensionName}

                                              showParameterModal={this.state.showParameterModal}
                                              onParamaterModal={this.onParamaterModal}
                                              innerEdit={this.innerEdit}
                                              SurveyTemplateId={SurveyTemplateId}
                                              handleQuesCancel={this.handleQuesCancel}
                                              GetParameterLabel={GetParameterLabel}
                                              showTranslatePage={showTranslatePage}
                                              translQuestion={this.state.translQuestion}
                                              handleTranslateQuestion={this.handleTranslateQuestion}
                                              handleTranslateOption={this.handleTranslateOption}
                                              onTranslateQuestionSave={this.onTranslateQuestionSave}
                                              translShortName={translShortName}
                                              handleLocationLable={this.handleLocationLable}
                                              surveyDetails={getSurveyData}

                                              dashType1Check={dashType1Check}

                                            />
                                          </>
                                          : null}





                                      </div> </>

                                    {(true) ?

                                      <>
                                        {!(currQuesEditId === question.id) ?

                                          <div className="absolute flex space-x-2 top-3 right-4">




                                            {(question && question.isNonEdit ? false : true) ?
                                              <>
                                                <button title="Edit" className="flex items-center justify-center w-8 h-8 text-[#212121] border rounded-full cursor-pointer hover:text-blue-500 hover:bg-blue-100"
                                                  onClick={() => this.onEditQues(question)}>
                                                  <TbPencil />
                                                </button>

                                                <button title="Move Down" className="inline-flex items-center justify-center w-8 h-8 mr-1 text-[#212121] border rounded-full cursor-pointer hover:text-blue-500 hover:bg-blue-100"
                                                  onClick={() => this.onCopyQues(question, question.surveyId)}>
                                                  <TbCopy />
                                                </button>


                                                <button title="Delete" className="flex items-center justify-center w-8 h-8 text-[#212121] border rounded-full cursor-pointer hover:text-blue-500 hover:bg-blue-100"
                                                  onClick={() => this.onDeleteConfirm(question)}>
                                                  <TbTrash />
                                                </button>
                                              </>
                                              : null}



                                            <button title="Move Up" className="inline-flex items-center justify-center w-8 h-8 mr-1 text-[#212121] border rounded-full cursor-pointer hover:text-blue-500 hover:bg-blue-100"
                                              onClick={() => this.onClickQuesUp(question.id, quesindex, question.surveyId)}>
                                              <TbChevronUp />
                                            </button>

                                            <button title="Move Down" className="inline-flex items-center justify-center w-8 h-8 mr-1 text-[#212121] border rounded-full cursor-pointer hover:text-blue-500 hover:bg-blue-100"
                                              onClick={() => this.onClickQuesDown(question.id, quesindex, question.surveyId)}>
                                              <TbChevronDown />
                                            </button>


                                          </div>

                                          : null}
                                      </>

                                      : null}

                                  </div>
                                )
                                : null}


                              {/* {(getSurveyData && getSurveyData.isNonEdit ? false : true) ? */}
                              {!(getSurveyData && getSurveyData.isNonEdit) ?

                                <div className="justify-center p-4 py-6 mt-4 mb-20 space-y-4 bg-white border border-gray-300 xl:px-4 md:flex md:space-x-4 md:space-y-0">

                                  <div className="relative flex items-center justify-between w-full px-4 py-2 border text-slate-800">

                                    <span className="w-full text-xs font-semibold cursor-pointer text-slate-800" onClick={() => this.handleShowQuestionType(showQuestionTypeList)}>
                                      {questionTypeTemplate.templateId ?
                                        QuestionTypeListData && QuestionTypeListData.length > 0 ?
                                          QuestionTypeListData.map((item, index) =>
                                            (!item.isDisable) ?
                                              (item.templateId === questionTypeTemplate.templateId) ?
                                                (item.name)
                                                : null
                                              : null
                                          ) : null
                                        :
                                        (LanguageObj && LanguageObj["choose_question_type_label_" + CurrentLanguage] ? LanguageObj["choose_question_type_label_" + CurrentLanguage] : "Choose Question Type")
                                      }
                                    </span>

                                    <span className="text-gray-500 cursor-pointer select-none material-symbols-outlined" onClick={() => this.handleShowQuestionType(showQuestionTypeList)}>expand_more</span>

                                    {showQuestionTypeList ?

                                      <div className={questionsList && questionsList.length > 0 ?
                                        "z-10 absolute left-0 bottom-12 bg-white shadow-xl  font-normal font-xs w-80 whitespace-nowrap overflow-hidden rounded-md border grid grid-cols-2 "
                                        : "z-10 absolute left-0 top-10 bg-white shadow-xl  font-normal font-xs w-80 whitespace-nowrap overflow-hidden rounded-md border grid grid-cols-2 "}
                                        id="chatbot" style={{ overflowY: 'auto', }}>

                                        {QuestionTypeListData && QuestionTypeListData.length > 0 ?
                                          QuestionTypeListData.map((item, index) =>
                                            (!item.isDisable) ?
                                              (item.templateId === questionTypeTemplate.templateId) ?

                                                <span key={index} onClick={() => this.handleQuestionType(item)} className="inline-flex items-center w-full px-4 py-3 text-xs bg-gray-100 border-b cursor-pointer"><i className="pr-4 fa fa-list" />
                                                  {item.name}</span>
                                                :
                                                <span key={index} onClick={() => this.handleQuestionType(item)} className="inline-flex items-center w-full px-4 py-3 text-xs border-b cursor-pointer hover:bg-blue-100"><i className="pr-4 fa fa-list" />
                                                  {item.name}</span>

                                              : null) : null}
                                      </div>
                                      : null}

                                  </div>

                                  <div className='flex w-full space-x-4 '>
                                    <span onClick={() => this.handleAddQuestion()} className="inline-flex items-center justify-center w-full py-2 text-sm text-center text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600">
                                      <span className="material-symbols-outlined mr-1 -mt-0.5" >
                                        add
                                      </span>
                                      {LanguageObj && LanguageObj["add_question_label_" + CurrentLanguage] ? LanguageObj["add_question_label_" + CurrentLanguage] : "Add Question"}
                                    </span>

                                    <span onClick={() => this.onQuestionLibrary()} className="inline-flex items-center justify-center w-full py-2 text-sm text-center text-white bg-green-500 rounded cursor-pointer hover:bg-green-600">

                                      {LanguageObj && LanguageObj["question_library_label_" + CurrentLanguage] ? LanguageObj["question_library_label_" + CurrentLanguage] : "Question Library"}
                                    </span>


                                  </div>

                                </div>
                                : null}



                            </div>
                          </main>

                          : null}










                        {showPreviewPage ?
                          <Preview
                            SurveyId={SurveyId}
                          />
                          : null}


                        {showPleaseSaveQuesModal ?
                          <QuestionRestrictionModal
                            currQuesEditId={currQuesEditId}
                            closeQuestionRestrictionModal={this.closeQuestionRestrictionModal}
                          />
                          : null}


                        {this.state.CurrentQuestionData ?
                          <DeleteSurveyConfirm
                            CurrentSurveyName={this.state.CurrentQuestionName}
                            handleSendSingleMail={this.onDeleteQues}
                            handleConfirmMailClose={this.handleConfirmDeleteQuesClose}
                          />
                          : null}


                        {showQuestionLibraryModal ?
                          <CustomQueLibrary
                            handleQuestionLibraryModalClose={this.handleQuestionLibraryModalClose}
                            getQuestionLibraryListData={getQuestionLibraryListData}
                            onSelectQLQue={this.onSelectQLQue}
                            QuestionLibraryFinalList={this.state.QuestionLibraryFinalList}
                            SubmitOfQuesLibList={this.SubmitOfQuesLibList}

                            handleOpenIndexFilter={this.handleOpenIndexFilter}
                            handleSelectIndexFilter={this.handleSelectIndexFilter}
                            showIndexFilter={this.state.showIndexFilter}
                            selectedIndexFilterValue={this.state.selectedIndexFilterValue}
                            GetParameterLabel={GetParameterLabel}
                            SelectOfQuesLibList={this.SelectOfQuesLibList}
                          />
                          : null}


                        {showTranslatePage ?

                          <main>


                            <div className="w-full px-4 py-6 mx-auto xl:w-4/6 lg:w-5/6">

                              <div className="mb-4">
                                <div className="pb-4 font-semibold text-slate-800">Languages</div>
                                <Select2
                                  id="langList"
                                  isMulti
                                  name="langList"
                                  defaultValue={(LangDefault) ? LangDefault.map((val) => { return val }) : ""}
                                  onChange={this.handleSurveyLangList}
                                  options={langListOptions}
                                  className="basic-multi-select"
                                  classNamePrefix="Activities"
                                />
                              </div>

                              {/* <div className="text-sm font-semibold text-slate-800">Translate Language</div> */}
                              <div onClick={() => this.setState({ showQuestionTypeList: false })} className="flex mt-2 -ml-2">
                                {LanguageTranslValueList && LanguageTranslValueList.length > 0 ?
                                  LanguageTranslValueList.map((item, index) =>
                                    <div
                                      onClick={() => this.handleTranlLanguage(item.shortName, translShortName)}
                                      className={item.shortName === translShortName ?
                                        "cursor-pointer  py-1 block px-4 text-blue-600 border-b-2 border-blue-500" :
                                        "cursor-pointer  py-1 block px-4"}
                                    >{item.name}</div>
                                  ) : null}
                              </div>


                              <div className="pb-4 mt-6 font-semibold text-slate-800">Translate Content {LabelTransl(translShortName) ? 'in "' + LabelTransl(translShortName) + '"' : ""}</div>



                              {ShowTranslWelcome ?
                                <>
                                  <div className="relative px-4 py-6 mb-2 bg-white border border-gray-300">

                                    <div className="absolute top-3 right-4">
                                      <span title="Translate" className="px-2 py-1 pb-2 text-white border border-green-500 rounded-sm cursor-pointer"
                                        onClick={() => this.onSaveTranslateWelcome(true)}><span className="text-sm text-green-500 ">Save</span>
                                      </span>

                                    </div>


                                    <div className="p-4 ">
                                      <span className="text-blue-500">Welcome To </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.name ? getSurveyData.name : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['name'] ?
                                            translSurvey['transl'][translShortName]['name'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyName(e, translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>


                                    {getSurveyData && getSurveyData.desc ?
                                      <div className="p-4 ">
                                        <span className="text-blue-500">Survey Desc: </span>

                                        <input
                                          type="text"
                                          value={getSurveyData && getSurveyData.desc ? getSurveyData.desc : ""}
                                          onChange={() => { }}
                                          disabled={true}
                                          className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                        />
                                        <input
                                          type="text"
                                          value={
                                            translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['desc'] ?
                                              translSurvey['transl'][translShortName]['desc'] : ""
                                          }
                                          placeholder="Translate here..."
                                          onChange={(e) => this.handleTranslSurveyDesc(e, translShortName)}
                                          className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                        />


                                      </div>
                                      : null}



                                    <div className="p-4 ">
                                      <span className="text-blue-500">Welcome Text: </span>
                                      <div className="p-2 mb-1 text-sm text-gray-400 border-2 border-gray-500" dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.welcomeText ? getSurveyData.welcomeText : "" }} />

                                      {/* <ReactQuill className="bg-white" theme="snow" value={getSurveyData && getSurveyData.welcomeText?getSurveyData.welcomeText:""} 
                              onChange={{}} placeholder="Welcome Text" disabled={true}/> */}


                                      <ReactQuill className="bg-white border-2 border-purple-500" theme="snow" value={
                                        translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['welcomeText'] ?
                                          translSurvey['transl'][translShortName]['welcomeText'] : ""
                                      } onChange={(val) => this.handleWelcomeTextTranslate(val, translShortName)} placeholder="Translate here..." />

                                    </div>

                                    {getSurveyData && getSurveyData.isInstruction ?
                                      <div className="p-4 ">
                                        <span className="text-blue-500">Instruction Text: </span>
                                        <div className="p-2 mb-1 text-sm text-gray-400 border-2 border-gray-500" dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.instructionText ? getSurveyData.instructionText : "" }} />
                                        <ReactQuill className="bg-white border-2 border-purple-500" theme="snow" value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['instructionText'] ?
                                            translSurvey['transl'][translShortName]['instructionText'] : ""
                                        } onChange={(val) => this.handleInstructionTextTranslate(val, translShortName)} placeholder="Translate here..." />
                                      </div>
                                      : null}



                                    <div className="p-4 ">
                                      <span className="text-blue-500">Thankyou Text: </span>
                                      <div className="p-2 mb-1 text-sm text-gray-400 border-2 border-gray-500" dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.thankyouText ? getSurveyData.thankyouText : "" }} />
                                      <ReactQuill className="bg-white border-2 border-purple-500" theme="snow" value={
                                        translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['thankyouText'] ?
                                          translSurvey['transl'][translShortName]['thankyouText'] : ""
                                      } onChange={(val) => this.handleThankyouTextTranslate(val, translShortName)} placeholder="Translate here..." />
                                    </div>


                                  </div>
                                </>
                                :
                                <>
                                  <div className="relative px-4 py-6 mb-2 bg-white border border-gray-300">

                                    <div className="absolute top-3 right-4">
                                      <span title="Translate" className="px-4 py-1 pb-2 text-white bg-blue-500 rounded-sm cursor-pointer"
                                        onClick={() => this.onTranslateWelcomePage(getSurveyData, true, translShortName)}><span className="text-sm ">Translate</span>
                                      </span>

                                    </div>


                                    <div className="p-4 ">
                                      <span className="text-blue-500">Welcome To </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.name ? getSurveyData.name : ""}</span>
                                    </div>
                                    {getSurveyData && getSurveyData.desc ?
                                      <div className="p-4 ">
                                        <span className="text-blue-500">Survey Desc: </span>
                                        <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.desc ? getSurveyData.desc : ""}</span>
                                      </div>
                                      : null}
                                    <div className="p-4 ">
                                      <span className="text-blue-500">Welcome Text: </span>
                                      <div dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.welcomeText ? getSurveyData.welcomeText : "" }} />
                                    </div>
                                    {getSurveyData && getSurveyData.isInstruction ?
                                      <div className="p-4 ">
                                        <span className="text-blue-500">Instruction Text: </span>
                                        <div dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.instructionText ? getSurveyData.instructionText : "" }} />
                                      </div>
                                      : null}
                                    <div className="p-4 ">
                                      <span className="text-blue-500">Thankyou Text: </span>
                                      <div dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.thankyouText ? getSurveyData.thankyouText : "" }} />
                                    </div>


                                  </div>
                                </>}


                              <div className="pb-4 mt-6 font-semibold text-slate-800">Translate Attributes {LabelTransl(translShortName) ? 'in "' + LabelTransl(translShortName) + '"' : ""}</div>

                              {showTranslAttributes ?
                                <>
                                  <div className="relative px-4 py-6 mb-2 bg-white border border-gray-300">

                                    <div className="absolute top-3 right-4">
                                      <span title="Translate" className="px-2 py-1 pb-2 text-white border border-green-500 rounded-sm cursor-pointer"
                                        onClick={() => this.onSaveTranslateWelcome(false)}><span className="text-sm text-green-500 ">Save</span>
                                      </span>

                                    </div>


                                    <div className="p-4 ">
                                      <span className="text-blue-500">StartButtonText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.StartButtonText ? getSurveyData.StartButtonText : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['StartButtonText'] ?
                                            translSurvey['transl'][translShortName]['StartButtonText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "StartButtonText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>
                                    {getSurveyData && getSurveyData.isInstruction ?

                                      <div className="p-4 ">
                                        <span className="text-blue-500">ContinueButtonText: </span>

                                        <input
                                          type="text"
                                          value={getSurveyData && getSurveyData.ContinueButtonText ? getSurveyData.ContinueButtonText : ""}
                                          onChange={() => { }}
                                          disabled={true}
                                          className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                        />
                                        <input
                                          type="text"
                                          value={
                                            translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['ContinueButtonText'] ?
                                              translSurvey['transl'][translShortName]['ContinueButtonText'] : ""
                                          }
                                          onChange={(e) => this.handleTranslSurveyInput(e.target.value, "ContinueButtonText", translShortName)}
                                          placeholder="Translate here..."
                                          className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                        />

                                      </div>
                                      : null}

                                    <div className="p-4 ">
                                      <span className="text-blue-500">SubmitButtonText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.SubmitButtonText ? getSurveyData.SubmitButtonText : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['SubmitButtonText'] ?
                                            translSurvey['transl'][translShortName]['SubmitButtonText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "SubmitButtonText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">okButtonText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.okButtonText ? getSurveyData.okButtonText : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['okButtonText'] ?
                                            translSurvey['transl'][translShortName]['okButtonText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "okButtonText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">WriteHereText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.WriteHereText ? getSurveyData.WriteHereText : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['WriteHereText'] ?
                                            translSurvey['transl'][translShortName]['WriteHereText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "WriteHereText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">ExpiryText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.ExpiryText ? getSurveyData.ExpiryText : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['ExpiryText'] ?
                                            translSurvey['transl'][translShortName]['ExpiryText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "ExpiryText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>


                                    <div className="p-4 ">
                                      <span className="text-blue-500">AlreadyText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.AlreadyText ? getSurveyData.AlreadyText : ""}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['AlreadyText'] ?
                                            translSurvey['transl'][translShortName]['AlreadyText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "AlreadyText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">mandatoryText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.mandatoryText ? getSurveyData.mandatoryText : "This question is mandatory"}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['mandatoryText'] ?
                                            translSurvey['transl'][translShortName]['mandatoryText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "mandatoryText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">allmandatoryText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.allmandatoryText ? getSurveyData.allmandatoryText : "All questions are mandatory"}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['allmandatoryText'] ?
                                            translSurvey['transl'][translShortName]['allmandatoryText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "allmandatoryText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">completedText: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.completedText ? getSurveyData.completedText : "completed"}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['completedText'] ?
                                            translSurvey['transl'][translShortName]['completedText'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "completedText", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>


                                    <div className="p-4 ">
                                      <span className="text-blue-500">instructionLabel: </span>

                                      <input
                                        type="text"
                                        value={getSurveyData && getSurveyData.instructionLabel ? getSurveyData.instructionLabel : "I N S T R U C T I O N"}
                                        onChange={() => { }}
                                        disabled={true}
                                        className="w-full px-4 py-2 mt-2 border-2 border-gray-500 outline-none"
                                      />
                                      <input
                                        type="text"
                                        value={
                                          translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['instructionLabel'] ?
                                            translSurvey['transl'][translShortName]['instructionLabel'] : ""
                                        }
                                        onChange={(e) => this.handleTranslSurveyInput(e.target.value, "instructionLabel", translShortName)}
                                        placeholder="Translate here..."
                                        className="w-full px-4 py-2 mt-2 border-2 border-purple-500 outline-none"
                                      />

                                    </div>

                                  </div>
                                </>
                                :
                                <>


                                  <div className="relative px-4 py-6 mb-2 bg-white border border-gray-300">

                                    <div className="absolute top-3 right-4">
                                      <span title="Translate" className="px-4 py-1 pb-2 text-white bg-blue-500 rounded-sm cursor-pointer"
                                        onClick={() => this.onTranslateWelcomePage(getSurveyData, false, translShortName)}><span className="text-sm ">Translate</span>
                                      </span>

                                    </div>


                                    <div className="p-4 ">
                                      <span className="text-blue-500">StartButtonText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.StartButtonText ? getSurveyData.StartButtonText : ""}</span>
                                    </div>
                                    {getSurveyData && getSurveyData.isInstruction ?
                                      <div className="p-4 ">
                                        <span className="text-blue-500">ContinueButtonText: </span>
                                        <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.ContinueButtonText ? getSurveyData.ContinueButtonText : ""}</span>
                                      </div>
                                      : null}

                                    <div className="p-4 ">
                                      <span className="text-blue-500">SubmitButtonText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.SubmitButtonText ? getSurveyData.SubmitButtonText : ""}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">okButtonText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.okButtonText ? getSurveyData.okButtonText : ""}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">WriteHereText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.WriteHereText ? getSurveyData.WriteHereText : ""}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">ExpiryText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.ExpiryText ? getSurveyData.ExpiryText : ""}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">AlreadyText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.AlreadyText ? getSurveyData.AlreadyText : ""}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">mandatoryText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.mandatoryText ? getSurveyData.mandatoryText : "This question is mandatory"}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">allmandatoryText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.allmandatoryText ? getSurveyData.allmandatoryText : "All questions are mandatory"}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">completedText: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.completedText ? getSurveyData.completedText : "completed"}</span>
                                    </div>

                                    <div className="p-4 ">
                                      <span className="text-blue-500">instructionLabel: </span>
                                      <span className="px-1 font-bold text-gray-500">{getSurveyData && getSurveyData.instructionLabel ? getSurveyData.instructionLabel : "instructionLabel"}</span>
                                    </div>

                                  </div>
                                </>
                              }




                              <div className="pb-4 text-sm font-semibold text-slate-800">Questions</div>
                              {questionsList && questionsList.length > 0 ?
                                questionsList.map((question, quesindex) =>
                                  <div onClick={() => this.setState({ showQuestionTypeList: false })} className="relative px-4 py-6 mb-2 bg-white border border-gray-300">
                                    <><div className="pt-6"> {this.QuestionComponent(question, quesindex, currQuesEditId, SurveyTemplateId, GetParameterLabel, translShortName, dashType1Check, surveyModuleNo)} </div> </>


                                    {!(currQuesEditId === question.id) ?
                                      <>
                                        <div className="absolute top-3 right-4">
                                          <span title="Translate" className="px-4 py-1 pb-2 text-white bg-blue-500 rounded-sm cursor-pointer"
                                            onClick={() => this.onTranslateQues(question, translShortName)}><span className="text-sm ">Translate</span>
                                          </span>

                                        </div>
                                      </>
                                      : null}

                                  </div>
                                )
                                : null}

                            </div>
                          </main>

                          : null}




                        {/* Create New Parameter */}
                        {this.state.showCreateParameterModal ?
                          <CreateParameterModal
                            handleConfirmMailClose={this.onCreateNewBucket}
                            parameterFormData={this.state.parameterFormData}
                            handleNewParameterInput={this.handleNewParameterInput}
                            getParameterTypeListData={getParameterTypeListData}
                            handleNewParameterTypeInput={this.handleNewParameterTypeInput}
                            handleNewParameterSubmit={this.handleNewParameterSubmit}
                            handleClearParameterForm={this.handleClearParameterForm}
                            isBucket={isBucket}
                            getParaLibList={getParameterLibraryListbyParameterTypeData}
                            handleCollectParaLib={this.handleCollectParaLib}
                            handleSubmitCopyParaLibrary={this.handleSubmitCopyParaLibrary}
                          />
                          : null}


                      </div>
                    </div>
                  </>
                  :
                  <>
                    {!loading ?
                      <div className="container h-full px-4 py-12 mx-auto overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                        <div className="flex items-center justify-center w-full h-full">
                          {/*  text start*/}
                          <div className="text-center">
                            <img alt="icon" src="img/empty_survey.png" className="mb-4" />
                            <h1 className="text-xl font-semibold text-gray-500 cursor-default">You have no access <br /> to this module!</h1>
                          </div>
                        </div>
                      </div>
                      : null}
                  </>
                }
              </main>
            </div>
          </div>


          {openAddNudgeModal && (<>
            <NudgeModal
              handleAddNudgeModal={this.handleNudgeModal}
              question={openAddNudgeModal}
              getNudgeLibListData={getNudgeLibListData}
              addNudgeToQues={this.addNudgeToQues}
              getFormattedParameter={getFormattedParameter}
              GetParameterLabel={GetParameterLabel}
              crrRangeList={crrRangeList}
            />
          </>)}


          {openAddDMGModal && (<>

            <div className="bg-black bg-opacity-40 fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center lg:p-0 p-4">
              <div className="xl:w-8/12 lg:w-5/6 w-full  mx-auto bg-white rounded-md   ">
                <div className="flex items-center justify-between p-4 border-b">
                  {" "}
                  <h1 className="text-lg p-1 font-semibold capitalize">{"Demographic Configuration"}</h1>
                  <span
                    onClick={() => this.handleDMGModal(false)}
                    className=" material-symbols-outlined bg-gray-50 hover:bg-gray-100 rounded-full p-1.5  cursor-pointer transition duration-150"
                  >
                    close
                  </span>
                </div>


                <div
                  className="lg:p-6 p-4  h-[40rem]  overflow-y-auto space-y-6" >
                  <div className=" space-y-4" >

                    <div className="border border-4 border-gray-500  p-4 bg-white rounded-lg flex justify-between ">
                      <div>
                        <div>
                          <div className="flex  ">
                            <span className="px-2 px-1 text-md leading-7 font-bold">{"Question:"}</span>
                            <span className="px-2 px-1 text-md leading-7 font-normal">{openAddDMGModal.name}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>

                    <div className="border border-4 border-gray-500  p-4 bg-white rounded-lg flex justify-between ">
                      <div className='flex p-2'>
                        {dmgModuleList && dmgModuleList.length > 0 ?
                          dmgModuleList.map((element) =>
                            <div onClick={() => this.handleDMGType(element)} className={
                              ((element === (openAddDMGModal.dmgModule === null ? 'NONE' : openAddDMGModal.dmgModule)) ? 'bg-blue-500' : 'bg-gray-500') + ' cursor-pointer mx-1 text-white px-2 py-1 rounded-lg'}>{
                                element}</div>
                          ) : null}
                      </div>
                    </div>


                    <div className="border border-4 border-gray-500  p-4 bg-white rounded-lg flex justify-between ">
                      <div className=" p-4 bg-white rounded-lg flex justify-between">
                        <SelectFilter
                          showFilter={this.state.showFilter}
                          showValue={this.state.showValue}
                          closeFilter={this.closeFilter}
                          EmpFilterData2={getSurveyDMGLaunchFilterData}
                          getIndexFilterData={getSurveyLaunchByDMGValueData}
                          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
                          getEMPFilterLabel={getEMPFilterLabel}
                          filterXList={openAddDMGModal.filters}
                        />
                      </div>
                    </div>

                    <div className="space-y-4 ">
                      <button
                        onClick={() => this.submitDemographicModal(openAddDMGModal)}
                        className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-base  border-[#2196F3] flex justify-center items-center px-4 py-2.5 rounded-md w-full "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-circle-plus"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          />
                          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                          <rect x="9" y="3" width="6" height="4" rx="2" />
                          <line x1="9" y1="12" x2="9.01" y2="12" />
                          <line x1="13" y1="12" x2="15" y2="12" />
                          <line x1="9" y1="16" x2="9.01" y2="16" />
                          <line x1="13" y1="16" x2="15" y2="16" />
                        </svg>
                        <span>ADD DEMOGRAPHICS TO QUESTION</span>
                      </button>

                    </div>



                  </div>

                </div>



              </div>
            </div>

          </>)}



          {showModifyIndexModal && (<>
            <ModifyIndexModal
              CurrentSurveyName={this.state.CurrentQuestionName}
              handleSendSingleMail={this.onDeleteQues}
              onCloseModal={this.openModifyIndexModal}
              questionsList={questionsList}
              onDeleteQuestionList={this.onDeleteQuestionList}
            />
          </>)}


        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, questions, user, dashboard } = state;
  return {
    loggingIn,
    survey,
    questions,
    user,
    dashboard
  };
}
export default connect(mapStateToProps)(AddQuestion);
