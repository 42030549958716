import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import Select2  from 'react-select';
// import { SketchPicker } from 'react-color';

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
  }

  render() {
    let { welcomeFormData, handleWelcomeTextInput, handleInstructionTextInput, handleThankyouTextInput, handleInstructionCheck, handleDisclaimerTextInput, handleDisclaimerCheck } = this.props;


    return (

      <main>
        {/* <div className="cursor-pointer item-end" onClick={()=>saveWelcomePage()}>save</div> */}
        <div className="py-6 xl:w-4/6 lg:w-5/6 px-4  w-full mx-auto space-y-8">
          <div className=" w-full block space-y-4 ">
            <div className='flex justify-between items-center'>
              <h2 className="text-[#212121] font-semibold text-md ">Welcome Content</h2>
              <button onClick={() => handleWelcomeTextInput(" [variable] ", 2)}
                className='cursor-pointer text-xs py-2 px-4 font-medium bg-[#2196f3] text-white rounded-md m-2 '>
                Add Reviewee Variable</button>
            </div>
            <ReactQuill className="bg-white " theme="snow" value={welcomeFormData.welcomeText} onChange={handleWelcomeTextInput} placeholder="Survey description" />
          </div>

          <div className="my-4">
            <div className="text-[#212121] font-semibold text-md pb-4">Instruction Content
              <input className="mx-2 mt-2" type="checkbox" checked={welcomeFormData.isInstruction} onClick={() => handleInstructionCheck(welcomeFormData.isInstruction)} onChange={() => { }} />
            </div>

            <ReactQuill className="bg-white" theme="snow" value={welcomeFormData.instructionText} onChange={handleInstructionTextInput} />

          </div>

          <div className="my-4">
            <div className="text-[#212121] font-semibold text-md pb-4">Thankyou Content</div>
            <ReactQuill className="bg-white " theme="snow" value={welcomeFormData.thankyouText} onChange={handleThankyouTextInput} />
          </div>

          <div className="my-4">
            <div className="text-[#212121] font-semibold text-md pb-4">Disclaimer Content
              <input className="mx-2 mt-2" type="checkbox" checked={welcomeFormData.isDisclaimer} onClick={() => handleDisclaimerCheck(welcomeFormData.isDisclaimer)} onChange={() => { }} />
            </div>

            <ReactQuill className="bg-white" theme="snow" value={welcomeFormData.disclaimer} onChange={handleDisclaimerTextInput} />

          </div>

        </div>
      </main>

    );
  }
}
function mapStateToProps(state) {
  ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey } = state;
  return {
    loggingIn,
    survey
  };
}
export default connect(mapStateToProps)(WelcomePage);
