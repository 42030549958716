import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PageTemplate from './PageTemplate';
import EmployeeDropdown from './Components/EmployeeDropdown';
import LanguageDropdown from './Components/LanguageDropdown';
import AssessmentScore from './Components/AssessmentScore';
import AssessmentScoreRTL from './Components/AssessmentScoreRTL';

import SummaryScore from './Components/SummaryScore';



class AssessmentTop5Summary extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();
    this.state = {
      crrSelectedEmp: "",
      languageName: 'English',
      languageShortName: 'en'
    }
  }

  componentDidMount() {

  }

  exportPDFWithComponent = () => {
    let { handleDownloadStatus } = this.props;
    // handleDownloadStatus("Detail Report");
    if (this.pdfExportComponent.current) {
      this.pdfExportComponent.current.save();
    }
  };




  handleSelectEmployee = (value) => {
    this.setState({ crrSelectedEmp: value }, () => {
      let { onAssessmentByEmpID } = this.props;
      let emp_id = "";
      if (value && value.emp_id) {
        emp_id = value.emp_id;
      } else {
        emp_id = value.emp_id;
      }
      onAssessmentByEmpID(emp_id)

    })
  }

  handleLanguageLabel = (data) => {
    this.setState({
      languageName: data.name,
      languageShortName: data.shortName
    })
  }



  render() {

    let { getLanguageData, downloadRTLReport, surveyLanguageCode, surveyLanguageRTL, isUser, loading,
       createdAt, getAssessmenrEmployeeDetailsData, closeReportModal,
      getAssessmentReportByEmpIDData, getTranslateElementAllData, getAnsweredEmployeesData } = this.props;

    let { languageName, languageShortName } = this.state;



    const GetTextByLanguage = (name, text) => {
      if (languageShortName && languageShortName !== "en") {
        let sentence = "";
        if (getTranslateElementAllData && getTranslateElementAllData.length > 0) {
          let getIndex = getTranslateElementAllData.findIndex(prev => prev.name === name);
          if (getIndex !== -1) {
            sentence = getTranslateElementAllData && getTranslateElementAllData[getIndex] && getTranslateElementAllData[getIndex]["transl"] && getTranslateElementAllData[getIndex]["transl"][languageShortName] ? getTranslateElementAllData[getIndex]["transl"][languageShortName] : ""
          }
        }
        return sentence ? sentence : text
      } else {
        return text
      }
    }

    const GetDetailsByLanguage = (element, name, text) => {
      if (languageShortName && languageShortName !== "en") {
        let sentence = element && element["details"] && element["details"]["transl"] && element["details"]["transl"][languageShortName] && element["details"]["transl"][languageShortName][name] ?
          element["details"]["transl"][languageShortName][name] : ""
        return sentence ? sentence : text
      } else {
        return text
      }
    }




    function EditButtonFn(name, text) {
      return (
        <>
          {GetTextByLanguage(name, text)}
        </>
      )
    }
    function EditDetailsFn(element, name, text) {
      return (
        <>
          {GetDetailsByLanguage(element, name, text)}
        </>
      )
    }


    return (


      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-black bg-opacity-60" >
        <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

          <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md 2xl:w-7/12 xl:w-9/12 lg:w-5/6 customscroll5 h-[calc(100vh-2rem)]" >

            <div className="sticky top-0 z-20 flex flex-col items-center justify-between p-4 px-6 bg-white border-b lg:flex-row gap-4"  >
              <h1 className="text-base font-semibold text-[#212121] md:text-lg ">
                TalentPlus Summary
              </h1>

              <div className='flex md:flex-row flex-col items-center gap-2 md:w-auto w-full'>

                <LanguageDropdown
                  getLanguageData={getLanguageData}
                  languageName={languageName}
                  languageShortName={languageShortName}
                  handleLanguageLabel={this.handleLanguageLabel}
                />

                <EmployeeDropdown
                  getAnsweredEmployeesData={getAnsweredEmployeesData}
                  crrSelectedEmp={this.state.crrSelectedEmp}
                  handleSelectEmployee={this.handleSelectEmployee}
                />

                {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?


                  <div onClick={() => this.exportPDFWithComponent()}
                    className="flex items-center py-3.5 px-4 text-sm border rounded-md cursor-pointer font-medium text-[#212121] md:w-auto w-full" >
                    <span class="material-symbols-outlined mr-2">
                      file_download
                    </span> Download PDF
                  </div>

                  : null}

                <div onClick={() => closeReportModal(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                  <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                  </svg>
                </div>
              </div>

            </div>


            {getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0 ?
              <div className=''>
                <PDFExport ref={this.pdfExportComponent}
                  pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} />}
                  scale={0.6}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  margin={0}
                  fileName={'Assessment Report' + (getAssessmenrEmployeeDetailsData && getAssessmenrEmployeeDetailsData.name ?
                    ' - ' + getAssessmenrEmployeeDetailsData.name : "")}
                  author="Happyplus Team"
                  landscape={false}
                >

                  {surveyLanguageRTL ?
                    <AssessmentScoreRTL
                      crrSelectedEmp={this.state.crrSelectedEmp}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                    :
                    <AssessmentScore
                      crrSelectedEmp={this.state.crrSelectedEmp}
                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                      getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                      EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                      createdAt={createdAt}
                      surveyLanguageRTL={surveyLanguageRTL}
                    />
                  }


                  <SummaryScore
                    crrSelectedEmp={this.state.crrSelectedEmp}
                    getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                    getAssessmenrEmployeeDetailsData={getAssessmenrEmployeeDetailsData}
                    EditDetailsFn={EditDetailsFn} EditButtonFn={EditButtonFn}
                    createdAt={createdAt}
                    surveyLanguageRTL={surveyLanguageRTL}
                  />

                </PDFExport>
              </div>
              :
              <>
                {loading ?
                  <div className='py-24 text-xl font-semibold text-center text-gray-500'>{"Please wait while report is generating..."}</div>
                  :
                  <div className='py-24 text-xl font-semibold text-center text-gray-500'>{"Report is not generated yet"}</div>
                }
              </>}
          </div>
        </div>
      </div>

    );
  }
}


function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(AssessmentTop5Summary);